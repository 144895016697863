<template>
  <div>
    <!-- <router-link  :to="{name: 'IntegrationPartners'}"> -->
    <div class="flex items-center mb-8" @click="backToIntegrationPartner()">
      <feather-icon class="text-primary" icon="ArrowLeftIcon"></feather-icon>
      <h6 class="text-primary">Integration Partners</h6>
    </div>
    <!-- </router-link> -->
    <vx-card class="setup-wizard-container">
      <div class="vx-row">
        <!-- steps left side-->
        <div class="vx-col md:w-1/4 mb-8 w-full">
          <ul class="setup-wizard mt-3">
            <!-- 1 Welcome -->
            <li
              class="
                flex
                items-center
                step
                justify-between
                w-full
                active
                blue-arrow
              "
            >
              <div>
                <p class="stepTitle">Welcome</p>
              </div>
              <div @click="navigateToCompletedTask(1)">
                <div class="step-icon-circle">
                  <span class="step-icon-text">1</span>
                </div>
              </div>
            </li>
            <!-- 2 Establish Connection-->
            <li class="flex items-center step justify-between w-full">
              <div>
                <p class="stepTitle">Establish Connection</p>
              </div>
              <div @click="takeConfirmationToNavigateStep2()">
                <div class="step-icon-circle">
                  <span class="step-icon-text">2</span>
                </div>
              </div>
            </li>
            <!-- 3 Import Users  -->
            <li class="flex items-center step justify-between w-full">
              <div>
                <p class="stepTitle">Import Users</p>
              </div>
              <div @click="navigateToCompletedTask(3)">
                <div class="step-icon-circle">
                  <span class="step-icon-text">3</span>
                </div>
              </div>
            </li>
            <!-- 4 Define CRM Data to Use -->
            <li class="flex items-center step justify-between w-full">
              <div>
                <p class="stepTitle">Define CRM Data to Use</p>
              </div>
              <div @click="navigateToCompletedTask(4)">
                <div class="step-icon-circle">
                  <span class="step-icon-text">4</span>
                </div>
              </div>
            </li>
            <!-- 5 Map Account Data Fields -->
            <li class="flex items-center step justify-between w-full">
              <div>
                <p class="stepTitle">Map Account Data Fields</p>
              </div>
              <div @click="navigateToCompletedTask(5)">
                <div class="step-icon-circle">
                  <span class="step-icon-text">5</span>
                </div>
              </div>
            </li>
            <!-- 6 Map Contact Data Fields -->
            <li class="flex items-center step justify-between w-full">
              <div>
                <p class="stepTitle">Map Contact Data Fields</p>
              </div>
              <div @click="navigateToCompletedTask(6)">
                <div class="step-icon-circle">
                  <span class="step-icon-text">6</span>
                </div>
              </div>
            </li>
            <!-- 7 Map Lead Data Fields -->
            <li class="flex items-center step justify-between w-full">
              <div>
                <p class="stepTitle">Map Lead Data Fields</p>
              </div>
              <div @click="navigateToCompletedTask(7)">
                <div class="step-icon-circle">
                  <span class="step-icon-text">7</span>
                </div>
              </div>
            </li>
            <!-- 8 Determine Lead Handling -->
            <li class="flex items-center step justify-between w-full">
              <div>
                <p class="stepTitle">Determine Lead Handling</p>
              </div>
              <div @click="navigateToCompletedTask(8)">
                <div class="step-icon-circle">
                  <span class="step-icon-text">8</span>
                </div>
              </div>
            </li>
            <!-- 9 Completed -->
            <li class="flex items-center step justify-between w-full">
              <div>
                <p class="stepTitle">Completed</p>
              </div>
              <div @click="navigateToCompletedTask(9)">
                <div class="step-icon-circle">
                  <span class="step-icon-text">9</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- steps content right side -->
        <div class="vx-col md:w-3/4 mb-8 w-full">
          <section class="setup-content">
            <!-- 1 Welcome content-->
            <div class="vx-row" v-show="activeStep == 1">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-3">Step 1 - Welcome</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla quam velit, vulputate eu pharetra nec, mattis ac
                    neque. Duis vulputate commodo lectus, ac blandit elit
                    tincidunt id. Sed rhoncus, tortor sed eleifend tristique,
                    tortor mauris molestie elit, et lacinia ipsum quam nec dui.
                    Quisque nec mauris sit amet elit iaculis pretium sit amet
                    quis magna. Aenean velit odio, elementum in tempus ut,
                    vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
                    vulputate eros sed felis sodales nec vulputate justo
                    hendrerit. Vivamus varius pretium ligula, a aliquam odio
                    euismod sit amet. Quisque laoreet sem sit amet orci
                    ullamcorper at ultricies metus viverra. Pellentesque arcu
                    mauris, malesuada quis ornare accumsan, blandit sed diam.
                  </p>
                </div>
              </div>
            </div>
            <!-- 2 Establish Connection content-->
            <div class="vx-row" v-show="activeStep == 2">
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-3">Step 2 - Establish Connection</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla quam velit, vulputate eu pharetra nec, mattis ac
                    neque. Duis vulputate commodo lectus, ac blandit elit
                    tincidunt id. Sed rhoncus, tortor sed eleifend tristique,
                    tortor mauris molestie elit, et lacinia ipsum quam nec dui.
                    Quisque nec mauris sit amet elit iaculis pretium sit amet
                    quis magna. Aenean velit odio, elementum in tempus ut,
                    vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
                    vulputate eros sed felis sodales nec vulputate justo
                    hendrerit. Vivamus varius pretium ligula, a aliquam odio
                    euismod sit amet. Quisque laoreet sem sit amet orci
                    ullamcorper at ultricies metus viverra. Pellentesque arcu
                    mauris, malesuada quis ornare accumsan, blandit sed diam.
                  </p>
                </div>
              </div>
              <hr />
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="common-table p-4">
                  <form data-vv-scope="establishConnectionForm">
                    <div class="vx-row">
                      <div class="vx-col mb-0 w-full">
                        <span class="vs-input--label w-full float-left"
                          >Connection Name</span
                        >
                      </div>
                      <div class="vx-col md:w-1/3 mb-6 w-full">
                        <div class="flex items-top">
                          <div class="w-full">
                            <vs-input
                              class="w-full"
                              v-model="connectionName"
                              name="connectionName"
                              v-validate="'required|min:1|max:255'"
                            />
                            <span class="text-danger text-sm error-msg">{{
                              errors.first(
                                "establishConnectionForm.connectionName"
                              )
                            }}</span>
                          </div>
                          <div class="pt-2">
                            <vx-tooltip text="Information">
                              <div class="badge badge-primary-light ml-1">
                                ?
                              </div>
                            </vx-tooltip>
                          </div>
                        </div>
                      </div>

                      <div
                        class="vx-col flex flex-wrap items-center mb-6 w-full"
                      >
                        <vs-checkbox
                          class="ml-0 my-1"
                          v-model="limitDailyAPICalls"
                          >Limit Daily API calls to</vs-checkbox
                        >
                        <vs-input
                          v-model="dailyAPICalls"
                          name="dailyAPICalls"
                          v-validate="'required|numeric'"
                          class="my-1"
                          :disabled="!limitDailyAPICalls"
                        />
                        <div>
                          <vx-tooltip text="Information">
                            <div class="badge badge-primary-light ml-1">?</div>
                          </vx-tooltip>
                        </div>
                        <span class="text-danger text-sm error-msg">{{
                          errors.first("establishConnectionForm.dailyAPICalls")
                        }}</span>
                      </div>
                      <div
                        class="vx-col flex flex-wrap items-center mb-6 w-full"
                      >
                        <vs-checkbox class="ml-0 my-1" v-model="limitMaxRecords"
                          >Limit maximum records to</vs-checkbox
                        >
                        <vs-input
                          v-model="maxRecords"
                          name="maxRecords"
                          v-validate="'required|numeric'"
                          class="my-1"
                          :disabled="!limitMaxRecords"
                        />
                        <div>
                          <vx-tooltip text="Information">
                            <div class="badge badge-primary-light ml-1">?</div>
                          </vx-tooltip>
                        </div>
                        <span class="text-danger text-sm error-msg">{{
                          errors.first("establishConnectionForm.maxRecords")
                        }}</span>
                      </div>
                      <div class="vx-col mb-6 w-full flex items-top">
                        <div class="pt-1">
                          <vs-checkbox
                            v-model="allowReadAccessFromIntegrationPartner"
                            name="allowReadAccessFromIntegrationPartner"
                            v-validate="'required:true'"
                            class="ml-0"
                            >Allow VisualVisitor to read data from your
                            system</vs-checkbox
                          >
                          <span class="text-danger text-sm error-msg">{{
                            errors.first(
                              "establishConnectionForm.allowReadAccessFromIntegrationPartner"
                            )
                          }}</span>
                        </div>
                        <div>
                          <vx-tooltip text="Information">
                            <div class="badge badge-primary-light ml-1">?</div>
                          </vx-tooltip>
                        </div>
                      </div>
                      <div class="vx-col mb-6 w-full flex items-center">
                        <vs-checkbox
                          v-model="allowWriteAccessIntoIntegrationPartner"
                          class="ml-0"
                          >Allow VisualVisitor to update and insert new records
                          into your system</vs-checkbox
                        >
                        <div>
                          <vx-tooltip text="Information">
                            <div class="badge badge-primary-light ml-1">?</div>
                          </vx-tooltip>
                        </div>
                      </div>
                      <div
                        class="vx-col flex flex-wrap items-center mb-6 w-full"
                      >
                        <div>
                          <vx-tooltip text="Information">
                            <div class="badge badge-primary-light ml-1">!</div>
                          </vx-tooltip>
                        </div>
                        <span class="vs-input--label pr-4 my-1 float-left"
                          >Select Integration Partner</span
                        >
                        <div class="vx-col md:w-1/4 mr-2 my-1 w-full">
                          <v-select
                            placeholder="Select"
                            label="Integration_Partner_Name"
                            :options="partnerOptions"
                            :reduce="(option) => option.Integration_Partner_ID"
                            v-model="integrationPartner"
                            :clearable="false"
                            :disabled="
                              integrationPartner && isCRMConnected
                                ? true
                                : false
                            "
                            class="w-auto"
                          />
                        </div>
                        <!-- <vs-button
                          title="Update"
                          color="warning"
                          type="filled"
                          class="mr-2 my-1"
                          :disabled="integrationPartner ? false : true"
                          @click="integrationPartner && isCRMConnected ? diconnectCRM() : secondStepSaveSettings()"
                          >{{isCRMConnected ? 'Disconnect' : 'Connect'}}</vs-button
                        > -->
                      </div>
                      <div class="vx-col flex items-center mb-6 w-full">
                        <span class="vs-input--label pr-4 float-left"
                          >Connection Status:</span
                        >
                        <p
                          class="mr-4"
                          :class="{
                            'text-success': isCRMConnected,
                            'text-danger': !isCRMConnected,
                          }"
                        >
                          {{ isCRMConnected ? "Connected" : "Not Connected" }}
                        </p>
                      </div>
                      <div
                        class="vx-col flex flex-wrap items-center mb-6 w-full"
                      >
                        <vs-button
                          title="Update"
                          color="warning"
                          type="filled"
                          class="mr-2 my-1"
                          :disabled="integrationPartner ? false : true"
                          @click="
                            integrationPartner && isCRMConnected
                              ? diconnectCRM()
                              : secondStepSaveSettings()
                          "
                          >{{
                            isCRMConnected ? "Disconnect" : "Connect"
                          }}</vs-button
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- 3 Import Users content -->
            <div class="vx-row" v-show="activeStep == 3">
              <!-- Welcome content-->
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-3">Step 3 - Import Users</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla quam velit, vulputate eu pharetra nec, mattis ac
                    neque. Duis vulputate commodo lectus, ac blandit elit
                    tincidunt id. Sed rhoncus, tortor sed eleifend tristique,
                    tortor mauris molestie elit, et lacinia ipsum quam nec dui.
                    Quisque nec mauris sit amet elit iaculis pretium sit amet
                    quis magna. Aenean velit odio, elementum in tempus ut,
                    vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
                    vulputate eros sed felis sodales nec vulputate justo
                    hendrerit. Vivamus varius pretium ligula, a aliquam odio
                    euismod sit amet. Quisque laoreet sem sit amet orci
                    ullamcorper at ultricies metus viverra. Pellentesque arcu
                    mauris, malesuada quis ornare accumsan, blandit sed diam.
                  </p>
                </div>
              </div>
              <hr />
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="flex items-center mb-6 w-full">
                  <vs-checkbox class="ml-0" v-model="sendSetupEmailToNewUsers"
                    >Send setup email to new users</vs-checkbox
                  >
                  <div>
                    <vx-tooltip text="Information">
                      <div class="badge badge-primary-light ml-1">?</div>
                    </vx-tooltip>
                  </div>
                </div>
                <div class="common-table p-4">
                  <!-- table -->
                  <vs-table
                    :data="usersToBeImported"
                    pagination
                    :max-items="itemsPerPage"
                    search
                  >
                    <template slot="thead">
                      <vs-th>
                        <vs-checkbox
                          v-model="selectedUsersToBeImportedAll"
                          @change="selectAllUsers()"
                        ></vs-checkbox
                      ></vs-th>
                      <vs-th>Last Name</vs-th>
                      <vs-th>First Name</vs-th>
                      <vs-th>Email</vs-th>
                      <vs-th>Title</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td>
                          <vs-checkbox
                            :disabled="tr.User_Exists"
                            @input="selectEachUser()"
                            v-model="selectedUsersToBeImported"
                            :vs-value="tr.User_Id"
                          ></vs-checkbox>
                        </vs-td>
                        <vs-td :data="data[indextr].Last_Name">
                          {{ data[indextr].Last_Name }}
                        </vs-td>
                        <vs-td :data="data[indextr].First_Name">
                          {{ data[indextr].First_Name }}
                        </vs-td>
                        <vs-td :data="data[indextr].Email">
                          {{ data[indextr].Email }}
                        </vs-td>
                        <vs-td :data="data[indextr].Title">
                          {{ data[indextr].Title }}
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </div>
            <!-- 4 Define CRM Data to Use content -->
            <div class="vx-row" v-show="activeStep == 4">
              <!-- Welcome content-->
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-3">Step 4 - Define CRM Data to Use</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla quam velit, vulputate eu pharetra nec, mattis ac
                    neque. Duis vulputate commodo lectus, ac blandit elit
                    tincidunt id. Sed rhoncus, tortor sed eleifend tristique,
                    tortor mauris molestie elit, et lacinia ipsum quam nec dui.
                    Quisque nec mauris sit amet elit iaculis pretium sit amet
                    quis magna. Aenean velit odio, elementum in tempus ut,
                    vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
                    vulputate eros sed felis sodales nec vulputate justo
                    hendrerit. Vivamus varius pretium ligula, a aliquam odio
                    euismod sit amet. Quisque laoreet sem sit amet orci
                    ullamcorper at ultricies metus viverra. Pellentesque arcu
                    mauris, malesuada quis ornare accumsan, blandit sed diam.
                  </p>
                </div>
              </div>
              <!-- Update your system's -->
              <!-- <div class="common-table mb-6 p-4">
                <form data-vv-scope="senderForm" class="px-3">
                  <div class="vx-row">
                    <div class="vx-col mb-2 w-full">
                      <div class="w-full flex items-center">
                        <span class="vs-input--label w-auto"
                          >Update your system's <u>Account</u> records using the
                          following field:</span
                        >
                        <div>
                          <vx-tooltip text="Information">
                            <div class="badge badge-primary-light ml-1">?</div>
                          </vx-tooltip>
                        </div>
                      </div>
                      <div
                        class="flex w-full flex-d-column justify-start mt-2 px-3"
                      >
                        <div class="w-full my-1">
                          <vs-radio v-model="updateFieldAccountRadio" vs-value="VisualVisitorID-Account">VisualVisitorID</vs-radio>
                        </div>
                        <div class="w-full my-1">
                          <vs-radio v-model="updateFieldAccountRadio" vs-value="UpdateFieldAccount">
                            <div class="md:w-4/4 mr-2 w-full">
                              <v-select
                                placeholder="Select"
                                label="Value"
                                :options="accountCRMFieldList"
                                :reduce="option => option.Value"
                                v-model="updateFieldAccount"
                                :clearable="false"
                                class="width-150"
                              />
                            </div>
                          </vs-radio>
                        </div>
                      </div>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <div class="w-full flex items-center">
                        <span class="vs-input--label w-auto"
                          >Update your system's <u>Contact</u> records using the
                          following field</span
                        >
                        <div>
                          <vx-tooltip text="Information">
                            <div class="badge badge-primary-light ml-1">?</div>
                          </vx-tooltip>
                        </div>
                      </div>
                      <div
                        class="flex w-full flex-d-column justify-start mt-2 px-3"
                      >
                        <div class="w-full my-1">
                          <vs-radio v-model="updateFieldContactRadio" :vs-value="'VisualVisitorID-Contact'">VisualVisitorID</vs-radio>
                        </div>
                        <div class="w-full my-1">
                          <vs-radio v-model="updateFieldContactRadio" vs-value="UpdateFieldContact">
                            <div class="md:w-4/4 mr-2 w-full">
                              <v-select
                                placeholder="Select"
                                label="Value"
                                :options="contactCRMFieldList"
                                :reduce="option => option.Value"
                                v-model="updateFieldContact"
                                :clearable="false"
                                class="width-150"
                              />
                            </div>
                          </vs-radio>
                        </div>
                      </div>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <div class="w-full flex items-center">
                        <span class="vs-input--label w-auto"
                          >Update your system's <u>Lead</u> records using the
                          following field</span
                        >
                        <div>
                          <vx-tooltip text="Information">
                            <div class="badge badge-primary-light ml-1">?</div>
                          </vx-tooltip>
                        </div>
                      </div>
                      <div
                        class="flex w-full flex-d-column justify-start mt-2 px-3"
                      >
                        <div class="w-full my-1">
                          <vs-radio v-model="updateFieldLeadRadio" :vs-value="'VisualVisitorID-Lead'">VisualVisitorID</vs-radio>
                        </div>
                        <div class="w-full my-1">
                          <vs-radio v-model="updateFieldLeadRadio" vs-value="UpdateFieldLead">
                            <div class="md:w-4/4 mr-2 w-full">
                              <v-select
                                placeholder="Select"
                                label="Value"
                                :options="leadCRMFieldList"
                                :reduce="option => option.Value"
                                v-model="updateFieldLead"
                                :clearable="false"
                                class="width-150"
                              />
                            </div>
                          </vs-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div> -->
              <!-- Prospect -->
              <div class="flex items-center mb-6 w-full">
                <vs-checkbox class="ml-0" v-model="useProspect">
                  <b>Use your Prospect records for workflow.</b>
                </vs-checkbox>
                <div>
                  <vx-tooltip text="Information">
                    <div class="badge badge-primary-light ml-1">?</div>
                  </vx-tooltip>
                </div>
              </div>
              <!-- Prospect Filter Logic -->
              <div
                v-show="useProspect"
                class="common-table p-4 mb-6 campaign-second"
              >
                <div class="flex w-full">
                  <h5 class="">
                    A Prospect is defined using the following logic in your
                    system.
                  </h5>
                </div>
                <form
                  data-vv-scope="senderForm"
                  class="from-to-camp"
                  v-if="filterLogicProspect"
                >
                  <div class="vx-row">
                    <div class="vx-col flex flex-wrap items-center mb-4 w-full">
                      <div class="flex-fill">
                        <p class=" pr-4 float-left">
                          Prospects are located in the following CRM Module :
                        </p>
                      </div>
                      <div class="width-200">
                        <v-select
                          label="Value"
                          :options="moduleList"
                          :reduce="(option) => option.Key"
                          v-model="prospectModule"
                          @input="changeModule('prospect')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="vx-row">
                  
                    <!-- Prospect Logic -->
                    <div class="vx-col w-full mb-8">
                      <div class="send-camp">
                        <div class="vx-row">
                            <div class="vx-col w-full find-people">
                              <div class="filter-campaign">
                                <div
                                  class="
                                    flex flex-wrap
                                    items-center
                                    mb-6
                                    w-full
                                  "
                                >
                                  <p class="pr-4 float-left w-auto my-2">If</p>
                                  <div class="md:w-1/3 pr-4 w-full my-2">
                                    <v-select
                                      label="Title"
                                      :options="logicalOptions"
                                      :reduce="(option) => option.Value"
                                      v-model="
                                        filterLogicProspect.Group_Condition_Met
                                      "
                                      :clearable="false"
                                    />
                                  </div>
                                  <p class="pr-4 float-left w-auto my-2">
                                    of the following conditions are met
                                  </p>
                                </div>
                                <div
                                  class="
                                    flex flex-wrap
                                    items-center
                                    mb-1
                                    w-full
                                  "
                                  v-for="(
                                    childLogicGroupRuleGroup, innerIndex
                                  ) in filterLogicProspect.Rule_Group"
                                  :key="innerIndex"
                                >
                                  <div class="md:w-1/5 pr-4 w-full my-2">
                                    <v-select
                                      label="Crm_Display_Name"
                                    :options="prospectModule ==1?accountCRMFieldList:prospectModule==2?contactCRMFieldList:prospectModule==3?leadCRMFieldList:[]"
                                      v-model="
                                        childLogicGroupRuleGroup.Crm_Api_Name
                                      "
                                      :clearable="false"
                                      @input="crmFieldChanged(innerIndex,'prospect')"
                                    />
                                  </div>
                                  <div class="md:w-1/5 pr-4 w-full my-2">
                                    <v-select
                                      label="Value"
                                      :options="
                                        childLogicGroupRuleGroup.Crm_Api_Name
                                          ? childLogicGroupRuleGroup
                                              .Crm_Api_Name.Operator_Type
                                          : []
                                      "
                                      v-model="
                                        childLogicGroupRuleGroup.Operator_Id
                                      "
                                      :clearable="false"
                                    />
                                  </div>
                                  <div class="md:w-2/5 pr-4 w-full my-2">
                                    <vs-input
                                      class="w-auto"
                                      v-model="childLogicGroupRuleGroup.Values"
                                      :disabled="true"
                                    />
                                  </div>
                                  <div
                                    class="
                                      table-action
                                      justify-center
                                      md:w-1/5
                                      w-full
                                      flex
                                      my-2
                                    "
                                  >
                                    <vs-button
                                      title="Copy"
                                      color="warning"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-menu"
                                      @click="
                                        getAvailableValues(
                                          childLogicGroupRuleGroup.Crm_Api_Name,
                                          childLogicGroupRuleGroup.Operator_Id,
                                          'prospect',
                                          innerIndex
                                        )
                                      "
                                      class="mr-2 float-left"
                                    ></vs-button>
                                    <vs-button
                                      title="Plus"
                                      color="success"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-plus"
                                      class="mr-2 float-left"
                                      @click="
                                        addRule('prospect', innerIndex)
                                      "
                                    ></vs-button>
                                    <vs-button
                                      title="Delete"
                                      color="danger"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-minus"
                                      class="mr-0"
                                      @click="deleteRule('prospect',innerIndex)"
                                    ></vs-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Customer -->
              <div class="mb-6 flex items-center w-full">
                <vs-checkbox class="ml-0" v-model="useCustomer">
                  <b>Use your Customer Records for workflow.</b>
                </vs-checkbox>
                <div>
                  <vx-tooltip text="Information">
                    <div class="badge badge-primary-light ml-1">?</div>
                  </vx-tooltip>
                </div>
              </div>
              <!-- Customer Filter Logic -->
              <div
                v-show="useCustomer"
                class="common-table p-4 mb-6 campaign-second"
              >
                <div class="flex w-full">
                  <h5 class="">
                    A Customer is defined using the following logic in your
                    system.
                  </h5>
                </div>
                <form
                  data-vv-scope="senderForm"
                  class="from-to-camp"
                  v-if="filterLogicCustomer"
                >
                  <div class="vx-row">
                    <div class="vx-col flex flex-wrap items-center mb-4 w-full">
                      <div class="flex-fill">
                        <p class="pr-4 float-left">
                          Customers are located in the following CRM Module :
                        </p>
                      </div>
                      <div class="width-200">
                        <v-select
                          label="Value"
                          :options="moduleList"
                          :reduce="(option) => option.Key"
                          v-model="customerModule"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="vx-row">
                    <!-- Customer Logic -->
                    <div class="vx-col w-full mb-8">
                      <div class="send-camp">
                        <div class="vx-row">
                            <div class="vx-col w-full find-people">
                              <div class="filter-campaign">
                                <div
                                  class="
                                    flex flex-wrap
                                    items-center
                                    mb-6
                                    w-full
                                  "
                                >
                                  <p class="pr-4 float-left w-auto my-2">If</p>
                                  <div class="md:w-1/3 pr-4 w-full my-2">
                                    <v-select
                                      label="Title"
                                      :options="logicalOptions"
                                      :reduce="(option) => option.Value"
                                      v-model="
                                        filterLogicCustomer.Group_Condition_Met
                                      "
                                      :clearable="false"
                                    />
                                  </div>
                                  <p class="pr-4 float-left w-auto my-2">
                                    of the following conditions are met
                                  </p>
                                </div>
                                <div
                                  class="
                                    flex flex-wrap
                                    items-center
                                    mb-1
                                    w-full
                                  "
                                  v-for="(
                                    childLogicGroupRuleGroup, innerIndex
                                  ) in filterLogicCustomer.Rule_Group"
                                  :key="innerIndex"
                                >
                                  <div class="md:w-1/5 pr-4 w-full my-2">
                                    <v-select
                                      label="Crm_Display_Name"
                                      :options="customerModule ==1?accountCRMFieldList:customerModule==2?contactCRMFieldList:customerModule==3?leadCRMFieldList:[]"
                                      v-model="
                                        childLogicGroupRuleGroup.Crm_Api_Name
                                      "
                                      :clearable="false"
                                       @input="crmFieldChanged(innerIndex,'customer')"
                                    />
                                  </div>
                                  <div class="md:w-1/5 pr-4 w-full my-2">
                                    <v-select
                                      label="Value"
                                      :options="
                                        childLogicGroupRuleGroup.Crm_Api_Name
                                          ? childLogicGroupRuleGroup
                                              .Crm_Api_Name.Operator_Type
                                          : []
                                      "
                                      v-model="
                                        childLogicGroupRuleGroup.Operator_Id
                                      "
                                      :clearable="false"
                                    />
                                  </div>
                                  <div class="md:w-2/5 pr-4 w-full my-2">
                                    <vs-input
                                      class="w-auto"
                                      v-model="childLogicGroupRuleGroup.Values"
                                      :disabled="true"
                                    />
                                  </div>
                                  <div
                                    class="
                                      table-action
                                      justify-center
                                      md:w-1/5
                                      w-full
                                      flex
                                      my-2
                                    "
                                  >
                                    <vs-button
                                      title="Copy"
                                      color="warning"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-menu"
                                      class="mr-2 float-left"
                                      @click="
                                        getAvailableValues(
                                          childLogicGroupRuleGroup.Crm_Api_Name,
                                          childLogicGroupRuleGroup.Operator_Id,
                                          'customer',
                                          innerIndex
                                        )
                                      "
                                    ></vs-button>
                                    <vs-button
                                      title="Plus"
                                      color="success"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-plus"
                                      class="mr-2 float-left"
                                      @click="
                                        addRule('customer', innerIndex)
                                      "
                                    ></vs-button>
                                    <vs-button
                                      title="Delete"
                                      color="danger"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-minus"
                                      class="mr-0"
                                      @click="deleteRule('customer',innerIndex)"
                                    ></vs-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Competitor -->
              <div class="mb-6 flex items-center w-full">
                <vs-checkbox class="ml-0" v-model="useCompetitor">
                  <b>Use your Competitor Records for workflow.</b>
                </vs-checkbox>
                <div>
                  <vx-tooltip text="Information">
                    <div class="badge badge-primary-light ml-1">?</div>
                  </vx-tooltip>
                </div>
              </div>
              <!-- Competitor Filter Logic -->
              <div
                v-show="useCompetitor"
                class="common-table p-4 campaign-second"
              >
                <div class="flex w-full">
                  <h5 class="">
                    A Competitor is defined using the following logic in your
                    system.
                  </h5>
                </div>
                <form
                  data-vv-scope="senderForm"
                  class="from-to-camp"
                  v-if="filterLogicCompetitor"
                >
                  <div class="vx-row">
                     <div class="vx-col flex flex-wrap items-center mb-4 w-full">
                      <div class="flex-fill">
                        <p class="pr-4 float-left">
                          Competitors are located in the following CRM Module :
                        </p>
                      </div>
                      <div class="width-200">
                       <v-select
                      label="Value"
                      :options="moduleList"
                      :reduce="(option) => option.Key"
                      v-model="competitorsModule"
                    />
                      </div>
                    </div>
                   
                  </div>
                  <div class="vx-row">
                    <!-- Competitor Logic -->
                    <div class="vx-col w-full mb-8">
                      <div class="send-camp">
                        <div class="vx-row">
                            <div class="vx-col w-full find-people">
                              <div class="filter-campaign">
                                <div
                                  class="
                                    flex flex-wrap
                                    items-center
                                    mb-6
                                    w-full
                                  "
                                >
                                  <p class="pr-4 float-left w-auto my-2">If</p>
                                  <div class="md:w-1/3 pr-4 w-full my-2">
                                    <v-select
                                      label="Title"
                                      :options="logicalOptions"
                                      :reduce="(option) => option.Value"
                                      v-model="
                                        filterLogicCompetitor.Group_Condition_Met
                                      "
                                      :clearable="false"
                                    />
                                  </div>
                                  <p class="pr-4 float-left w-auto my-2">
                                    of the following conditions are met
                                  </p>
                                </div>
                                <div
                                  class="
                                    flex flex-wrap
                                    items-center
                                    mb-1
                                    w-full
                                  "
                                  v-for="(
                                    childLogicGroupRuleGroup, innerIndex
                                  ) in filterLogicCompetitor.Rule_Group"
                                  :key="innerIndex"
                                >
                                  <div class="md:w-1/5 pr-4 w-full my-2">
                                    <v-select
                                      label="Crm_Display_Name"
                                        :options="competitorsModule ==1?accountCRMFieldList:competitorsModule==2?contactCRMFieldList:competitorsModule==3?leadCRMFieldList:[]"
                                      v-model="
                                        childLogicGroupRuleGroup.Crm_Api_Name
                                      "
                                      :clearable="false"
                                      @input="crmFieldChanged(innerIndex,'competitor')"
                                    />
                                  </div>
                                  <div class="md:w-1/5 pr-4 w-full my-2">
                                    <v-select
                                      label="Value"
                                      :options="
                                        childLogicGroupRuleGroup.Crm_Api_Name
                                          ? childLogicGroupRuleGroup
                                              .Crm_Api_Name.Operator_Type
                                          : []
                                      "
                                      v-model="
                                        childLogicGroupRuleGroup.Operator_Id
                                      "
                                      :clearable="false"
                                    />
                                  </div>
                                  <div class="md:w-2/5 pr-4 w-full my-2">
                                    <vs-input
                                      class="w-auto"
                                      v-model="childLogicGroupRuleGroup.Values"
                                      :disabled="true"
                                    />
                                  </div>
                                  <div
                                    class="
                                      table-action
                                      justify-center
                                      md:w-1/5
                                      w-full
                                      flex
                                      my-2
                                    "
                                  >
                                    <vs-button
                                      title="Copy"
                                      color="warning"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-menu"
                                      class="mr-2 float-left"
                                      @click="
                                        getAvailableValues(
                                          childLogicGroupRuleGroup.Crm_Api_Name,
                                          childLogicGroupRuleGroup.Operator_Id,
                                          'competitor',
                                          innerIndex
                                        )
                                      "
                                    ></vs-button>
                                    <vs-button
                                      title="Plus"
                                      color="success"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-plus"
                                      class="mr-2 float-left"
                                      @click="
                                        addRule('competitor', innerIndex)
                                      "
                                    ></vs-button>
                                    <vs-button
                                      title="Delete"
                                      color="danger"
                                      type="filled"
                                      icon-pack="feather"
                                      icon="icon-minus"
                                      class="mr-0"
                                      @click="deleteRule('competitor',innerIndex)"
                                    ></vs-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- 5 Map Data Fields content (Account) -->
            <div class="vx-row" v-show="activeStep == 5">
              <!-- Welcome content-->
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-3">Step 5 - Map Account Data Fields</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla quam velit, vulputate eu pharetra nec, mattis ac
                    neque. Duis vulputate commodo lectus, ac blandit elit
                    tincidunt id. Sed rhoncus, tortor sed eleifend tristique,
                    tortor mauris molestie elit, et lacinia ipsum quam nec dui.
                    Quisque nec mauris sit amet elit iaculis pretium sit amet
                    quis magna. Aenean velit odio, elementum in tempus ut,
                    vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
                    vulputate eros sed felis sodales nec vulputate justo
                    hendrerit. Vivamus varius pretium ligula, a aliquam odio
                    euismod sit amet. Quisque laoreet sem sit amet orci
                    ullamcorper at ultricies metus viverra. Pellentesque arcu
                    mauris, malesuada quis ornare accumsan, blandit sed diam.
                  </p>
                </div>
              </div>
              <!-- Account -->
              <div class="common-table mb-6 p-4">
                <div class="flex btn-search w-full px-4">
                  <h5 class="mb-2">{{ accountModuleName }} Mapping</h5>
                </div>
                <!-- table -->
                <vs-table
                  class="account-table"
                  :data="accountCRMAvailableFieldOptions"
                >
                  <template slot="thead">
                    <vs-th>ID Field</vs-th>
                    <vs-th>CRM Field Name</vs-th>
                    <vs-th>VisualVisitor Field</vs-th>
                    <vs-th>Validation Rules</vs-th>
                    <vs-th>Default Value</vs-th>
                    <vs-th>Overwrite</vs-th>
                    <vs-th>Example Data</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        <vs-radio
                          :disabled="!data[indextr].Update_Field"
                          v-model="keyFieldAccount"
                          :vs-value="data[indextr].Field_API_Name"
                          vs-name="keyField"
                        ></vs-radio>
                      </vs-td>
                      <vs-td
                        :class="
                          data[indextr].Required_Field ? 'text-danger' : ''
                        "
                        ><span v-if="data[indextr].Required_Field">*</span>
                        {{ data[indextr].Field_Display_Name }}
                      </vs-td>
                      <vs-td>
                        <v-select
                          :disabled="
                            tr.System_Field && accountFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                          :options="accountVVFieldOptions"
                          label="VV_Field_Display_Name"
                          v-model="data[indextr].CurrentRowValues.VV_Field_Id"
                          :selectable="
                            (option) =>
                              !accountCRMSelected.includes(option.VV_Field_Id)
                          "
                          @input="updateAccountFieldMapValues(indextr)"
                        />
                        <span
                          class="text-danger text-sm"
                          v-if="
                            !data[indextr].CurrentRowValues.VV_Field_Id &&
                            accountErrorMessage &&
                            accountErrorMessage.vvField.includes(indextr)
                          "
                          >Please select Visual Visitor Field</span
                        >
                      </vs-td>

                      <vs-td>
                        <v-select
                          v-model="
                            data[indextr].CurrentRowValues.Validation_Rule_Id
                          "
                          :reduce="(options) => options.Key"
                          label="Value"
                          :options="tr.Available_Validation_Rules"
                          :disabled="
                            tr.System_Field && accountFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                        />
                        <span
                          class="text-danger text-sm"
                          v-if="
                            !data[indextr].CurrentRowValues
                              .Validation_Rule_Id &&
                            accountErrorMessage &&
                            accountErrorMessage.ValidationRule.includes(indextr)
                          "
                          >Please select validation rule</span
                        >
                      </vs-td>
                      <vs-td>
                        <vs-input
                          v-model="data[indextr].CurrentRowValues.Default_Value"
                          class="w-auto"
                          placeholder="-- None --"
                          :disabled="
                            tr.System_Field && accountFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                        />
                      </vs-td>
                      <vs-td>
                        <vs-checkbox
                          v-model="data[indextr].CurrentRowValues.Overwrite"
                          :disabled="
                            tr.System_Field && accountFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                        >
                        </vs-checkbox>
                      </vs-td>
                      <vs-td>{{
                        data[indextr].CurrentRowValues.VV_Field_Id
                          ? data[indextr].CurrentRowValues.VV_Field_Id.Example
                          : ""
                      }}</vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
                <div class="w-full mt-4 float-left">
                  <vs-checkbox v-model="accountMapUpdateBlankFields"
                    >Do not update blank fields.
                  </vs-checkbox>
                </div>
              </div>
            </div>
            <!-- 6 Map Data Fields content (Contact) -->
            <div class="vx-row" v-show="activeStep == 6" id="step6">
              <!-- Welcome content-->
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-3">Step 6 - Map Contact Data Fields</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla quam velit, vulputate eu pharetra nec, mattis ac
                    neque. Duis vulputate commodo lectus, ac blandit elit
                    tincidunt id. Sed rhoncus, tortor sed eleifend tristique,
                    tortor mauris molestie elit, et lacinia ipsum quam nec dui.
                    Quisque nec mauris sit amet elit iaculis pretium sit amet
                    quis magna. Aenean velit odio, elementum in tempus ut,
                    vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
                    vulputate eros sed felis sodales nec vulputate justo
                    hendrerit. Vivamus varius pretium ligula, a aliquam odio
                    euismod sit amet. Quisque laoreet sem sit amet orci
                    ullamcorper at ultricies metus viverra. Pellentesque arcu
                    mauris, malesuada quis ornare accumsan, blandit sed diam.
                  </p>
                </div>
              </div>
              <!-- Contact -->
              <div class="common-table mb-6 p-4">
                <div class="flex btn-search w-full px-4">
                  <h5 class="mb-2">{{ contactModuleName }} Mapping</h5>
                </div>
                <!-- table -->
                <vs-table
                  class="account-table"
                  :data="contactCRMAvailableFieldOptions"
                >
                  <template slot="thead">
                    <vs-th>ID Field</vs-th>
                    <vs-th>CRM Field Name</vs-th>
                    <vs-th>VisualVisitor</vs-th>
                    <vs-th>Validation Rules</vs-th>
                    <vs-th>Default Value</vs-th>
                    <vs-th>Overwrite</vs-th>
                    <vs-th>Example Data</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        <vs-radio
                          :disabled="!data[indextr].Update_Field"
                          v-model="keyFieldContact"
                          :vs-value="data[indextr].Field_API_Name"
                          vs-name="keyFieldContact"
                        ></vs-radio>
                      </vs-td>
                      <vs-td
                        :class="
                          data[indextr].Required_Field ? 'text-danger' : ''
                        "
                      >
                        <span v-if="data[indextr].Required_Field">*</span>
                        {{ data[indextr].Field_Display_Name }}
                      </vs-td>
                      <vs-td>
                        <v-select
                          :disabled="
                            tr.System_Field && contactFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                          :options="contactVVFieldOptions"
                          label="VV_Field_Display_Name"
                          v-model="data[indextr].CurrentRowValues.VV_Field_Id"
                          :selectable="
                            (option) =>
                              !contactCRMSelected.includes(option.VV_Field_Id)
                          "
                          @input="updateContactFieldMapValues(indextr)"
                        />
                        <span
                          class="text-danger text-sm"
                          v-if="
                            !data[indextr].CurrentRowValues.VV_Field_Id &&
                            contactErrorMessage &&
                            contactErrorMessage.vvField.includes(indextr)
                          "
                          >Please select Visual Visitor Field</span
                        >
                      </vs-td>
                      <vs-td>
                        <v-select
                          v-model="
                            data[indextr].CurrentRowValues.Validation_Rule_Id
                          "
                          :reduce="(options) => options.Key"
                          label="Value"
                          :options="data[indextr].Available_Validation_Rules"
                          :disabled="
                            tr.System_Field && accountFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                        />
                        <span
                          class="text-danger text-sm"
                          v-if="
                            !data[indextr].CurrentRowValues
                              .Validation_Rule_Id &&
                            contactErrorMessage &&
                            contactErrorMessage.ValidationRule.includes(indextr)
                          "
                          >Please select validation rule</span
                        >
                      </vs-td>
                      <vs-td>
                        <vs-input
                          v-model="data[indextr].CurrentRowValues.Default_Value"
                          class="w-auto"
                          placeholder="-- None --"
                          :disabled="
                            tr.System_Field && accountFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                        />
                      </vs-td>
                      <vs-td>
                        <vs-checkbox
                          v-model="data[indextr].CurrentRowValues.Overwrite"
                          :disabled="
                            tr.System_Field && accountFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                        >
                        </vs-checkbox>
                      </vs-td>
                      <vs-td class="text-muted">
                        {{
                          data[indextr].CurrentRowValues.VV_Field_Id
                            ? data[indextr].CurrentRowValues.VV_Field_Id.Example
                            : ""
                        }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
                <div class="w-full mt-4 float-left">
                  <vs-checkbox v-model="contactMapUpdateBlankFields"
                    >Do not update blank fields.
                  </vs-checkbox>
                </div>
              </div>
            </div>
            <!-- 7 Map Data Fields content (Lead) -->
            <div class="vx-row" v-show="activeStep == 7">
              <!-- Welcome content-->
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-3">Step 7 - Map Lead Data Fields</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla quam velit, vulputate eu pharetra nec, mattis ac
                    neque. Duis vulputate commodo lectus, ac blandit elit
                    tincidunt id. Sed rhoncus, tortor sed eleifend tristique,
                    tortor mauris molestie elit, et lacinia ipsum quam nec dui.
                    Quisque nec mauris sit amet elit iaculis pretium sit amet
                    quis magna. Aenean velit odio, elementum in tempus ut,
                    vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
                    vulputate eros sed felis sodales nec vulputate justo
                    hendrerit. Vivamus varius pretium ligula, a aliquam odio
                    euismod sit amet. Quisque laoreet sem sit amet orci
                    ullamcorper at ultricies metus viverra. Pellentesque arcu
                    mauris, malesuada quis ornare accumsan, blandit sed diam.
                  </p>
                </div>
              </div>
              <!-- Lead -->
              <div class="common-table" v-show="!useContactMapForLead">
                <div class="flex btn-search w-full px-4">
                  <h5 class="mb-2">{{ leadModuleName }} Mapping</h5>
                </div>
                <!-- table -->
                <vs-table
                  class="account-table"
                  :data="leadCRMAvailableFieldOptions"
                >
                  <template slot="thead">
                    <vs-th>ID Field</vs-th>
                    <vs-th>CRM Field Name</vs-th>
                    <vs-th>VisualVisitor</vs-th>
                    <vs-th>Validation Rules</vs-th>
                    <vs-th>Default Value</vs-th>
                    <vs-th>Overwrite</vs-th>
                    <vs-th>Example Data</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        <vs-radio
                          :disabled="!tr.Update_Field"
                          v-model="keyFieldLead"
                          :vs-value="tr.Field_API_Name"
                          vs-name="keyFieldLead"
                        ></vs-radio
                      ></vs-td>
                      <vs-td :class="tr.Required_Field ? 'text-danger' : ''"
                        ><span v-if="tr.Required_Field">*</span>
                        {{ tr.Field_Display_Name }}
                      </vs-td>
                      <vs-td>
                        <v-select
                          :disabled="
                            tr.System_Field && leadFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                          :options="leadVVFieldOptions"
                          label="VV_Field_Display_Name"
                          v-model="tr.CurrentRowValues.VV_Field_Id"
                          :selectable="
                            (option) =>
                              !leadCRMSelected.includes(option.VV_Field_Id)
                          "
                          @input="updateLeadFieldMapValues(indextr)"
                        />
                        <span
                          class="text-danger text-sm"
                          v-if="
                            !data[indextr].CurrentRowValues.VV_Field_Id &&
                            leadErrorMessage &&
                            leadErrorMessage.vvField.includes(indextr)
                          "
                          >Please select visual visitor field
                        </span>
                      </vs-td>

                      <vs-td>
                        <v-select
                          v-model="
                            data[indextr].CurrentRowValues.Validation_Rule_Id
                          "
                          :reduce="(options) => options.Key"
                          label="Value"
                          :options="data[indextr].Available_Validation_Rules"
                          :disabled="
                            tr.System_Field && leadFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                        />
                        <span
                          class="text-danger text-sm"
                          v-if="
                            !data[indextr].CurrentRowValues
                              .Validation_Rule_Id &&
                            leadErrorMessage &&
                            leadErrorMessage.ValidationRule.includes(indextr)
                          "
                          >Please select validation rule</span
                        >
                      </vs-td>
                      <vs-td>
                        <vs-input
                          v-model="data[indextr].CurrentRowValues.Default_Value"
                          class="w-auto"
                          placeholder="-- None --"
                          :disabled="
                            tr.System_Field && leadFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                        />
                      </vs-td>
                      <vs-td>
                        <vs-checkbox
                          v-model="data[indextr].CurrentRowValues.Overwrite"
                          :disabled="
                            tr.System_Field && leadFieldMapped.length
                              ? tr.System_Field
                              : false
                          "
                        >
                        </vs-checkbox>
                      </vs-td>
                      <vs-td>
                        {{
                          data[indextr].CurrentRowValues.VV_Field_Id
                            ? data[indextr].CurrentRowValues.VV_Field_Id.Example
                            : ""
                        }}</vs-td
                      >
                    </vs-tr>
                  </template>
                </vs-table>
                <div class="w-full mt-4 float-left">
                  <vs-checkbox v-model="leadMapUpdateBlankFields"
                    >Do not update blank fields.
                  </vs-checkbox>
                </div>
              </div>
            </div>
            <!-- 8 Determine Lead Handling content -->
            <div class="vx-row" v-show="activeStep == 8">
              <!-- Welcome content-->
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-3">Step 8 - Determine Lead Handling</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla quam velit, vulputate eu pharetra nec, mattis ac
                    neque. Duis vulputate commodo lectus, ac blandit elit
                    tincidunt id. Sed rhoncus, tortor sed eleifend tristique,
                    tortor mauris molestie elit, et lacinia ipsum quam nec dui.
                    Quisque nec mauris sit amet elit iaculis pretium sit amet
                    quis magna. Aenean velit odio, elementum in tempus ut,
                    vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
                    vulputate eros sed felis sodales nec vulputate justo
                    hendrerit. Vivamus varius pretium ligula, a aliquam odio
                    euismod sit amet. Quisque laoreet sem sit amet orci
                    ullamcorper at ultricies metus viverra. Pellentesque arcu
                    mauris, malesuada quis ornare accumsan, blandit sed diam.
                  </p>
                </div>
              </div>
              <!-- Global - Lead Handling Rules -->
              <form data-vv-scope="leadHandlingForm" class="px-3">
                <div class="common-table mb-6 p-4">
                  <div class="flex w-full">
                    <h5 class="mb-6">Global - Lead Handling Rules</h5>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col flex flex-wrap items-center mb-2 w-full">
                      <div class="flex-fill">
                        <span class="vs-input--label pr-4 float-left"
                          >When no owner is found for an {{ accountText }} the
                          record should be assigned to:</span
                        >
                      </div>
                      <div class="width-200 flex-1">
                        <v-select
                          label="Value"
                          class="w-full"
                          :options="userList"
                          :reduce="(option) => option.Key"
                          v-model="defaultAccountOwnerUserId"
                          name="defaultAccountOwnerUserId"
                          v-validate="'required'"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.defaultAccountOwnerUserId"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col flex-wrap flex items-center mb-2 w-full">
                      <div class="flex-fill">
                        <span class="vs-input--label pr-4 float-left"
                          >When no owner is found for a {{ contactText }} the
                          record should be assigned to:</span
                        >
                      </div>
                      <div class="width-200 flex-1">
                        <v-select
                          label="Value"
                          class="w-full"
                          :options="userList"
                          :reduce="(option) => option.Key"
                          v-model="defaultContactOwnerUserId"
                          v-validate="'required'"
                          name="defaultContactOwnerUserId"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.defaultContactOwnerUserId"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col flex flex-wrap items-center mb-2 w-full">
                      <div class="flex-fill my-1">
                        <span class="vs-input--label pr-4 float-left"
                          >When no owner is found for an {{ leadText }} the
                          record should be assigned to:</span
                        >
                      </div>
                      <div class="width-200 flex-1">
                        <v-select
                          label="Value"
                          class="w-full"
                          :options="userList"
                          :reduce="(option) => option.Key"
                          v-model="defaultLeadOwnerUserId"
                          name="defaultLeadOwnerUserId"
                          v-validate="'required'"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.defaultLeadOwnerUserId"
                          )
                        }}</span>
                      </div>
                    </div>
                    <!-- Importing Acount, Contact, Lead -->
                    <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full"
                        >When importing {{ accountText }} into your
                        system:</span
                      >
                      <div class="flex w-full flex-wrap items-center mt-2 px-3">
                        <div
                          class="flex-1 my-1 pr-1"
                          v-for="(
                            checkboxOption, index
                          ) in importingIntoSystemListOptions"
                          :key="index"
                        >
                          <vs-radio
                            v-model="importToCRMAccountOption"
                             :disabled="(importToCRMContactOption ==3 && importToCRMLeadOption==3)?false:true"
                            :vs-name="'importToCRMAccountOption'"
                            name="importToCRMAccountOption"
                            v-validate="'required'"
                            :vs-value="checkboxOption.Key"
                          >
                            {{ checkboxOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.importToCRMAccountOption"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full"
                        >When importing {{ contactText }} into your
                        system:</span
                      >
                      <div class="flex flex-wrap w-full items-center mt-2 px-3">
                        <div
                          class="flex-1 my-1 pr-1"
                          v-for="(
                            checkboxOption, index
                          ) in importingIntoSystemListOptions"
                          :key="index"
                        >
                          <vs-radio
                            v-model="importToCRMContactOption"
                            name="importToCRMContactOption"
                            :vs-name="'importToCRMContactOption'"
                            :vs-value="checkboxOption.Key"
                            v-validate="'required'"
                          >
                            {{ checkboxOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.importToCRMContactOption"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full"
                        >When importing {{ leadText }} into Salesforce:</span
                      >
                      <div class="flex flex-wrap w-full items-center mt-2 px-3">
                        <div
                          class="flex-1 my-1 pr-1"
                          v-for="(
                            checkboxOption, index
                          ) in importingIntoSystemListOptions"
                          :key="index"
                        >
                          <vs-radio
                            v-model="importToCRMLeadOption"
                            :vs-name="'importToCRMLeadOption'"
                            name="importToCRMLeadOption"
                            :vs-value="checkboxOption.Key"
                            v-validate="'required'"
                          >
                            {{ checkboxOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger text-sm error-msg">{{
                          errors.first("leadHandlingForm.importToCRMLeadOption")
                        }}</span>
                      </div>
                    </div>
                    <!-- How to handle duplicate -->
                    <!-- <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full"
                        >How to handle duplicate records?</span
                      >
                      <div class="flex flex-wrap w-full items-center mt-2 px-3">
                        <div
                          class="flex-1 my-1 pr-1"
                          v-for="(
                            handlingDuplicateListOption, index
                          ) in handlingDuplicateListOptions"
                          :key="index"
                        >
                          <vs-radio
                            v-model="importToCRMDuplicateHandlingOption"
                            name="importToCRMDuplicateHandlingOption"
                            :vs-name="'importToCRMDuplicateHandlingOption'"
                            v-validate="'required'"
                            :vs-value="handlingDuplicateListOption.Key"
                          >
                            {{ handlingDuplicateListOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.importToCRMDuplicateHandlingOption"
                          )
                        }}</span>
                      </div>
                    </div> -->
                    <div class="vx-col mb-6 w-full">
                      <span class="vs-input--label w-full"
                        >Do not import a {{ contactText }} or
                        {{ leadText }} into Salesforce if the record is
                        missing:</span
                      >
                      <div class="flex flex-wrap w-full items-center mt-2 px-3">
                        <div
                          class="my-1 pr-1"
                          v-for="(
                            doNotImportListOption, index
                          ) in doNotImportListOptions"
                          :key="index"
                        >
                          <vs-checkbox
                            v-model="doNotImport"
                            class="ml-0"
                            :vs-value="doNotImportListOption.Key"
                            >{{ doNotImportListOption.Value }}</vs-checkbox
                          >
                        </div>
                      </div>
                      
                    </div>
                     <div
                        class="vx-col flex flex-wrap items-center mb-2 w-full"
                        >
                        <div class="">
                          <span class="vs-input--label pr-4"
                            >When import is completed send notification to:</span
                          >
                        </div>
                        <div class=" lg:w-1/2 w-full">
                         <vs-input class="w-full" placeholder="example@visualvisitor.com, example1@visualvisitor.com" v-model="importNotificationEmail" name="importNotificationEmail" />
                        </div>
                        <!-- <span class="text-danger" v-if="validateMultipleEmailsCommaSeparated()">Invalid Email Id</span> -->
                      </div>
                  </div>
                  <!-- Preferences when exporting data to VisualVisitor -->
                  <div class="flex w-full">
                    <h5 class="mb-6">
                      Preferences when exporting data to VisualVisitor
                    </h5>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full">
                        When exporting {{ accountText }} data from your system
                        and the record matches an {{ accountText }} record in
                        VisualVisitor the:
                      </span>
                      <div
                        class="
                          flex
                          w-full
                          flex-d-column
                          justify-start
                          mt-2
                          px-3
                        "
                      >
                        <div
                          class="w-full my-1"
                          v-for="(
                            checkboxOption, index
                          ) in exportingIntoSystemListOptions"
                          :key="index"
                        >
                          <vs-radio
                            v-model="exportToCRMAccountOption"
                            :vs-name="'exportToCRMAccountOption'"
                            name="exportToCRMAccountOption"
                            v-validate="'required'"
                            :vs-value="checkboxOption.Key"
                          >
                            {{ checkboxOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.exportToCRMAccountOption"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full">
                        When exporting {{ contactText }} data from your system
                        and the record matches an {{ contactText }} record in
                        VisualVisitor the:
                      </span>
                      <div
                        class="
                          flex
                          w-full
                          flex-d-column
                          justify-start
                          mt-2
                          px-3
                        "
                      >
                        <div
                          class="w-full my-1"
                          v-for="(
                            checkboxOption, index
                          ) in exportingIntoSystemListOptions"
                          :key="index"
                        >
                          <vs-radio
                            v-model="exportToCRMContactOption"
                            :vs-name="'exportToCRMContactOption'"
                            name="exportToCRMContactOption"
                            v-validate="'required'"
                            :vs-value="checkboxOption.Key"
                          >
                            {{ checkboxOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.exportToCRMContactOption"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full">
                        When exporting {{ leadText }} data from your system and
                        the record matches an {{ leadText }} record in
                        VisualVisitor the:
                      </span>
                      <div
                        class="
                          flex
                          w-full
                          flex-d-column
                          justify-start
                          mt-2
                          px-3
                        "
                      >
                        <div
                          class="w-full my-1"
                          v-for="(
                            checkboxOption, index
                          ) in exportingIntoSystemListOptions"
                          :key="index"
                        >
                          <vs-radio
                            v-model="exportToCRMLeadOption"
                            :vs-name="'exportToCRMLeadOption'"
                            name="exportToCRMLeadOption"
                            :vs-value="checkboxOption.Key"
                            v-validate="'required'"
                          >
                            {{ checkboxOption.Value }}
                          </vs-radio>
                        </div>
                        <span class="text-danger text-sm error-msg">{{
                          errors.first("leadHandlingForm.exportToCRMLeadOption")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                        class="vx-col flex flex-wrap items-center mb-2 w-full"
                        >
                        <div class="">
                          <span class="vs-input--label pr-4"
                            >When import is completed send notification to:</span
                          >
                        </div>
                        <div class=" lg:w-1/2 w-full ">
                         <vs-input class="w-full" v-model="exportNotificationEmail" name="exportNotificationEmail" placeholder="example@visualvisitor.com, example1@visualvisitor.com"  />
                        </div>
                      </div>
                </div>
                <!-- VisualVisitor Module - Lead Handling Rules -->
                <div class="card-title more-card-title">
                  <h2 class="mb-0">
                    VisualVisitor Module - Lead Handling Rules
                  </h2>
                </div>
                <!-- Module - Who's Looking -->
                <div class="common-table mb-6 p-4">
                  <div class="flex w-full">
                    <h5 class="mb-6">Module - Who's Looking</h5>
                  </div>
                  <div class="vx-row">
                    <!-- export START -->
                    <div class="vx-col mb-2 w-full">
                      <vs-checkbox
                        class="ml-0"
                        v-model="allowQuickExportLooking"
                      >
                        Allow users to manually export into your system
                      </vs-checkbox>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full pl-0">
                        When a new
                        <span class="text-warning cursor-pointer">
                         Export
                        </span>
                        is generated and the lead does not exist in {{ crmNameToDisplay }}:
                      </span>
                      <div
                        class="
                          flex
                          w-full
                          flex-d-column
                          justify-start
                          mt-2
                          pl-8
                        "
                        v-for="(radioOption, index) in recordListOptions"
                        :key="index"
                      >
                        <vs-radio      
                          v-model="lookingQuickExportOptionId"                 
                          name="lookingQuickExportOptionId"
                          :vs-name="'lookingQuickExportOptionId'"
                          class="my-1"
                          :vs-value="radioOption.Key"  
                          v-validate="'required'"        
                        >
                         {{ radioOption.Value }}
                        </vs-radio>
                      </div>
                      <span class="text-danger pl-8 text-sm error-msg">{{
                        errors.first("leadHandlingForm.lookingQuickExportOptionId")
                      }}</span>
                    </div>
                    <div class="vx-col flex items-start mb-2 w-full">
                      <span class="vs-input--label pr-4 pt-5 float-left pl-0">
                        Tag New
                        <span class="text-warning cursor-pointer">
                         Export
                        </span>
                         record as a 
                      </span>
                      <div class="md:w-1/3 pr-4 w-full my-2">
                        <v-select
                          label="Value"
                          :options="classifications"
                          :reduce="(option) => option.Key"
                          v-model="lookingQuickExportClassificationId"
                          name="lookingQuickExportClassificationId"
                          v-validate="'required'"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.lookingQuickExportClassificationId"
                          )
                        }}</span>
                      </div>
                    </div>
                    <!--   Form Capture Start --> 
                    <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full pl-0">
                        When a new
                        <span class="text-warning cursor-pointer">
                          Form Capture
                        </span>
                        is generated and the lead does not exist in {{ crmNameToDisplay }}:
                      </span>
                      <div
                        class="
                          flex
                          w-full
                          flex-d-column
                          justify-start
                          mt-2
                          pl-8
                        "
                      >
                        <vs-radio
                          v-model="formCaptureOptionId"
                          v-validate="'required'"
                          name="formCaptureOptionId"
                          :vs-name="'formCaptureOptionId'"
                          class="my-1"
                          :vs-value="formCaptureOption.Key"
                          v-for="(
                            formCaptureOption, index
                          ) in recordListOptions"
                          :key="index"
                        >
                          {{ formCaptureOption.Value }}
                        </vs-radio>
                      </div>
                      <span class="text-danger pl-8 text-sm error-msg">{{
                        errors.first("leadHandlingForm.formCaptureOptionId")
                      }}</span>
                    </div>
                    <div class="vx-col flex items-start mb-2 w-full">
                      <span class="vs-input--label pr-4 pt-5 float-left pl-0">
                        Tag New
                        <span class="text-warning cursor-pointer">
                          Form Capture
                        </span>
                        as a
                      </span>
                      <div class="md:w-1/3 pr-4 w-full my-2">
                        <v-select
                          label="Value"
                          :options="classifications"
                          :reduce="(option) => option.Key"
                          v-model="formCaptureClassificationId"
                          name="formCaptureClassificationId"
                          v-validate="'required'"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.formCaptureClassificationId"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col mb-8 w-full">
                      <vs-checkbox class="ml-0" v-model="formCaptureInsertNote">
                        Add a note to the Record in {{ crmNameToDisplay }} when a new
                        <span class="text-warning cursor-pointer">
                          Form Capture
                        </span>
                        is generated.
                      </vs-checkbox>
                    </div>
                    <!-- Form Capture STOP -->
                    <!-- Indentified Website Visitor START -->
                    <div class="vx-col mb-2 w-full">
                      <vs-checkbox
                        class="ml-0"
                        v-model="webVisitorOptionEnabled"
                      >
                        When an
                        <span class="text-warning cursor-pointer">
                          Identified Website Visitor
                        </span>
                        has:
                      </vs-checkbox>
                    </div>
                    <div class="vx-col flex flex-wrap items-top pl-8 w-full">
                      <span class="vs-input--label pr-2 pt-5 float-left"
                        >Page visits is greater than
                      </span>
                      <div class="md:w-1/6 pr-2 w-full my-2">
                        <vs-input
                          name="webVisitorOptionVisitThreshold"
                          v-model="webVisitorOptionVisitThreshold"
                          v-validate="'required|numeric'"
                          class="w-auto"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.webVisitorOptionVisitThreshold"
                          )
                        }}</span>
                      </div>
                      <span class="vs-input--label pr-2 pt-5 float-left"
                        >within a period of
                      </span>
                      <div class="md:w-1/6 pr-2 w-full my-2">
                        <vs-input
                          name="webVisitorOptionVisitPeriod"
                          v-model="webVisitorOptionVisitPeriod"
                          v-validate="'required|numeric'"
                          class="w-auto"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.webVisitorOptionVisitPeriod"
                          )
                        }}</span>
                      </div>
                      <span class="vs-input--label pr-2 pt-5 float-left">
                        and the visitor does not exist in your system:
                      </span>
                    </div>
                    <div
                      class="
                        flex
                        w-full
                        flex-d-column
                        justify-start
                        mt-2
                        pl-8
                        px-3
                      "
                    >
                      <div
                        class="w-full my-1 pl-5"
                        v-for="(webVisitorOption, index) in recordListOptions"
                        :key="index"
                      >
                        <vs-radio
                          v-model="webVisitorOptionId"
                          :vs-name="'webVisitorOptionId'"
                          name="webVisitorOptionId"
                          :vs-value="webVisitorOption.Key"
                          v-validate="'required'"
                        >
                          {{ webVisitorOption.Value }}
                        </vs-radio>
                      </div>
                      <span class="text-danger pl-5 text-sm error-msg">{{
                        errors.first("leadHandlingForm.webVisitorOptionId")
                      }}</span>
                    </div>
                    <div class="vx-col flex items-top mb-2 w-full">
                      <span class="vs-input--label pr-4 pt-5 pl-0 float-left">
                        Tag new
                        <span class="text-warning cursor-pointer">
                          Identified Website Visitor
                        </span>
                        as a
                      </span>
                      <div class="md:w-1/3 pr-4 w-full my-2">
                        <v-select
                          label="Value"
                          :options="classifications"
                          :reduce="(option) => option.Key"
                          v-model="webVisitorClassificationId"
                          name="webVisitorClassificationId"
                          v-validate="'required'"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.webVisitorClassificationId"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <vs-checkbox class="ml-0" v-model="webVisitorInsertNote">
                        Add a note to the record in your system when an
                        <span class="text-warning cursor-pointer">
                          Identified Website Visitor
                        </span>
                        has:
                      </vs-checkbox>
                    </div>
                    <div class="vx-col flex flex-wrap pl-8 items-top w-full">
                      <span class="vs-input--label pr-2 pt-5 float-left">
                        Page visits is greater than
                      </span>
                      <div class="md:w-1/6 pr-2 w-full my-2">
                        <vs-input
                          name="webVisitorNoteOptionVisitThreshold"
                          v-model="webVisitorNoteOptionVisitThreshold"
                          v-validate="'required|numeric'"
                          class="w-auto"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.webVisitorNoteOptionVisitThreshold"
                          )
                        }}</span>
                      </div>
                      <span class="vs-input--label pr-2 pt-5 float-left"
                        >within a period of
                      </span>
                      <div class="md:w-1/6 pr-2 w-full my-2">
                        <vs-input
                          name="webVisitorNoteOptionVisitPeriod"
                          v-model="webVisitorNoteOptionVisitPeriod"
                          v-validate="'required|numeric'"
                          class="w-auto"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.webVisitorNoteOptionVisitPeriod"
                          )
                        }}</span>
                      </div>
                      <span class="vs-input--label pr-2 pt-5 float-left"
                        >days.
                      </span>
                    </div>
                    <!-- Indentified Website Visitor STOP -->
                  </div>
                </div>
                <!-- Module - Who's Shopping -->
                <div class="common-table mb-6 p-4">
                  <div class="flex w-full">
                    <h5 class="mb-6">Module - Who's Shopping</h5>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col mb-2 w-full">
                      <vs-checkbox
                        class="ml-0"
                        v-model="allowQuickExportShopping"
                      >
                        Allow users to manually export into your system
                      </vs-checkbox>
                    </div>
                       <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full pl-0">
                        When a new
                        <span class="text-warning cursor-pointer">
                         Export
                        </span>
                        is generated and the shopper does not exist in {{ crmNameToDisplay }}:
                      </span>
                      <div
                        class="
                          flex
                          w-full
                          flex-d-column
                          justify-start
                          mt-2
                          pl-8
                        "
                        v-for="(shoppingExportOption, index) in recordListOptions" :key="index"
                      >
                        <vs-radio                       
                          class="my-1"
                           v-model="shoppingQuickExportOptionId"
                          :vs-name="'shoppingQuickExportOptionId'"
                           name="shoppingQuickExportOptionId"
                          :vs-value="shoppingExportOption.Key"  
                          v-validate="'required'"                
                        >
                        {{ shoppingExportOption.Value }}
                        </vs-radio>
                      </div>
                      <span class="text-danger pl-8 text-sm error-msg">{{
                        errors.first("leadHandlingForm.shoppingQuickExportOptionId")
                      }}</span>
                    </div>
                    <div class="vx-col flex items-start mb-2 w-full">
                      <span class="vs-input--label pr-4 pt-5 float-left pl-0">
                        Tag New
                        <span class="text-warning cursor-pointer">
                         Export
                        </span>
                         record as a 
                      </span>
                      <div class="md:w-1/3 pr-4 w-full my-2">
                        <v-select
                          label="Value"
                          :options="classifications"
                          :reduce="(option) => option.Key"
                          v-model="shoppingQuickExportClassificationId"
                          name="shoppingQuickExportClassificationId"
                          v-validate="'required'"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.shoppingQuickExportClassificationId"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <vs-checkbox class="ml-0" v-model="shoppingOptionEnabled">
                        When an identified
                        <span class="text-warning cursor-pointer">
                          Shopping Company
                        </span>
                        has:
                      </vs-checkbox>
                    </div>
                    <div class="vx-col flex flex-wrap items-top w-full pl-8">
                      <span class="vs-input--label pr-2 pt-5 float-left">
                        Score greater than
                      </span>
                      <div class="md:w-1/6 pr-2 w-full my-2">
                        <vs-input
                          name="shoppingOptionScoreThreshold"
                          v-model="shoppingOptionScoreThreshold"
                          v-validate="'required|numeric'"
                          class="w-auto"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.shoppingOptionScoreThreshold"
                          )
                        }}</span>
                      </div>
                      <span class="vs-input--label pr-2 pt-5 float-left">
                        within a period of
                      </span>
                      <div class="md:w-1/6 pr-2 w-full my-2">
                        <vs-input
                          name="shoppingOptionScorePeriod"
                          v-model="shoppingOptionScorePeriod"
                          v-validate="'required|numeric'"
                          class="w-auto"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.shoppingOptionScorePeriod"
                          )
                        }}</span>
                      </div>
                      <span class="vs-input--label pr-2 pt-5 float-left">
                        and the shopper does not exist in your system:
                      </span>
                    </div>
                    <div
                      class="
                        flex
                        w-full
                        flex-d-column
                        justify-start
                        mt-2
                        px-3
                        pl-8
                      "
                    >
                      <div
                        class="w-full my-1 pl-5"
                        v-for="(
                          shoppingRecordOption, index
                        ) in recordListOptions"
                        :key="index"
                      >
                        <vs-radio
                          v-model="shoppingOptionId"
                          :vs-name="'shoppingOptionId'"
                          name="shoppingOptionId"
                          v-validate="'required'"
                          :vs-value="shoppingRecordOption.Key"
                        >
                          {{ shoppingRecordOption.Value }}
                        </vs-radio>
                      </div>
                      <span class="text-danger pl-5 text-sm error-msg">{{
                        errors.first("leadHandlingForm.shoppingOptionId")
                      }}</span>
                    </div>
                    <div class="vx-col flex items-top mb-2 w-full">
                      <span class="vs-input--label pr-4 pt-5 pl-0 float-left">
                        Tag new
                        <span class="text-warning cursor-pointer">
                          Shopping Company
                        </span>
                        employees as a
                      </span>
                      <div class="md:w-1/3 pr-4 w-full my-2">
                        <v-select
                          label="Value"
                          :options="classifications"
                          :reduce="(option) => option.Key"
                          v-model="shoppingClassificationId"
                          name="shoppingClassificationId"
                          v-validate="'required'"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.shoppingClassificationId"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="vx-col mb-2 w-full">
                      <vs-checkbox class="ml-0" v-model="shoppingInsertNote">
                        Add a note to the record in your system when an
                        <span class="text-warning cursor-pointer">
                          Shopping Company
                        </span>
                        has:
                      </vs-checkbox>
                    </div>
                    <div class="vx-col flex flex-wrap items-top pl-8 w-full">
                      <span class="vs-input--label pr-2 pt-5 float-left">
                        Score greater than
                      </span>
                      <div class="md:w-1/6 pr-2 w-full my-2">
                        <vs-input
                          name="shoppingNoteOptionScoreThreshold"
                          v-model="shoppingNoteOptionScoreThreshold"
                          v-validate="'required|numeric'"
                          class="w-auto"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.shoppingNoteOptionScoreThreshold"
                          )
                        }}</span>
                      </div>
                      <span class="vs-input--label pr-2 pt-5 float-left">
                        within a period of
                      </span>
                      <div class="md:w-1/6 pr-2 w-full my-2">
                        <vs-input
                          name="shoppingNoteOptionScorePeriod"
                          v-model="shoppingNoteOptionScorePeriod"
                          v-validate="'required|numeric'"
                          class="w-auto"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.shoppingNoteOptionScorePeriod"
                          )
                        }}</span>
                      </div>
                      <span class="vs-input--label pr-2 pt-5 float-left"
                        >days.
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Module - Who To Contact -->
                <div class="common-table mb-6 p-4">
                  <div class="flex w-full">
                    <h5 class="mb-6">Module - Who To Contact</h5>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col mb-2 w-full">
                      <vs-checkbox
                        class="ml-0"
                        v-model="allowQuickExportContact"
                      >
                        Allow users to manually export into your system
                      </vs-checkbox>
                    </div>
                     <div class="vx-col mb-2 w-full">
                      <span class="vs-input--label w-full pl-0">
                        When a new
                        <span class="text-warning cursor-pointer">
                         Export
                        </span>
                        is generated and the contact does not exist in {{ crmNameToDisplay }}:
                      </span>
                      <div
                        class="
                          flex
                          w-full
                          flex-d-column
                          justify-start
                          mt-2
                          pl-8
                        "
                         v-for="(
                            moduleExportOption, index
                          ) in recordListOptions"
                          :key="index"
                      >
                        <vs-radio                       
                           v-model="contactQuickExportOptionId"
                          name="contactQuickExportOptionId"
                          :vs-name="'contactQuickExportOptionId'"
                          class="my-1"
                          :vs-value="moduleExportOption.Key"   
                          v-validate="'required'"               
                        >
                          {{ moduleExportOption.Value }}
                        </vs-radio>
                      </div>
                      <span class="text-danger pl-8 text-sm error-msg">{{
                        errors.first("leadHandlingForm.contactQuickExportOptionId")
                      }}</span>
                    </div>
                    <div class="vx-col flex items-start mb-2 w-full">
                      <span class="vs-input--label pr-4 pt-5 float-left pl-0">
                        Tag New
                        <span class="text-warning cursor-pointer">
                         Export
                        </span>
                         record as a 
                      </span>
                      <div class="md:w-1/3 pr-4 w-full my-2">
                        <v-select
                          label="Value"
                          :options="classifications"
                          :reduce="(option) => option.Key"
                          v-model="contactQuickExportClassificationId"
                          name="contactQuickExportClassificationId"
                          v-validate="'required'"
                        />
                        <span class="text-danger text-sm error-msg">{{
                          errors.first(
                            "leadHandlingForm.contactQuickExportClassificationId"
                          )
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Module - Call Tracking -->
                <div class="common-table mb-6 p-4">
                  <div class="flex w-full">
                    <h5 class="mb-6">Module - Call Tracking</h5>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col mb-2 w-full">
                      <vs-checkbox
                        class="ml-0"
                        v-model="callTrackingInsertNote"
                      >
                        Add a note to the {{ crmNameToDisplay }} record with a matching
                        phone number
                      </vs-checkbox>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- 9 Completed content -->
            <div class="vx-row" v-show="activeStep == 9">
              <!-- Welcome content-->
              <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
                <div class="card-title more-card-title">
                  <h2 class="mb-3">Step 9 - Completed</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla quam velit, vulputate eu pharetra nec, mattis ac
                    neque. Duis vulputate commodo lectus, ac blandit elit
                    tincidunt id. Sed rhoncus, tortor sed eleifend tristique,
                    tortor mauris molestie elit, et lacinia ipsum quam nec dui.
                    Quisque nec mauris sit amet elit iaculis pretium sit amet
                    quis magna. Aenean velit odio, elementum in tempus ut,
                    vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut
                    vulputate eros sed felis sodales nec vulputate justo
                    hendrerit. Vivamus varius pretium ligula, a aliquam odio
                    euismod sit amet. Quisque laoreet sem sit amet orci
                    ullamcorper at ultricies metus viverra. Pellentesque arcu
                    mauris, malesuada quis ornare accumsan, blandit sed diam.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!--  -->
      <div class="float-left text-right mt-6 mb-8 w-full">
        <vs-button
          type="filled"
          color="primary"
          :disabled="isFirstStepDisabled"
          @click="validateStep()"
          >{{ LabelConstant.buttonLabelNext }}
        </vs-button>
        <vs-button
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          @click="cancelSetupConnectionPopup = true"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vx-card>
    <!-- warning popup before opening 'Type Base Input Value Popup' -->
    <vs-popup class="warning-popup" :active.sync="editRulePopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="AlertTriangleIcon"></feather-icon>
            </div>
            <h4>Please select the appropriate values.</h4>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          color="grey"
          @click="editRulePopup = false"
          type="border"
          class="grey-btn mr-3"
          >{{ LabelConstant.buttonLabelOkay }}
        </vs-button>
      </div>
    </vs-popup>
    <!-- Type Base Input Value Popup  -->
    <vs-popup class="" title="" :active.sync="isTypeBaseInputValuePopup">
      <template>
        <form data-vv-scope="typeBaseInputValueForm">
          <div class="vx-row find-people">
            <div class="vx-col mb-3 w-full">
              <p class="w-full float-left">
                {{ crmApiName ? crmApiName.Crm_Display_Name : "" }}
                {{ operatorType ? operatorType.Value : "" }}
              </p>
              <div class="vx-row align-center">
                <div class="vx-col mb-3 w-full">
                  <!-- <input-tag
                    v-if="
                      (listInputCriteriaBaseType == 'text' ||
                        listInputCriteriaBaseType == 'number' ||
                        listInputCriteriaBaseType == 'decimal') &&
                      listInputIsOperatorList == true &&
                      listInputIsPickList == false
                    "
                    v-model="ruleGroupInputValueMultiple"
                    name="ruleGroupInputValueMultiple"
                    v-validate="'required'"
                  ></input-tag> -->
                  <span
                    class="text-danger text-sm"
                    v-if="
                      (listInputCriteriaBaseType == 'text' ||
                        listInputCriteriaBaseType == 'number') &&
                      listInputIsOperatorList == true
                    "
                    >{{
                      errors.first("typeBaseInputValueForm.ruleGroupInputValue")
                    }}</span
                  >
                  <vs-input
                    v-if="
                      (listInputCriteriaBaseType == 'text' ||
                        listInputCriteriaBaseType == 'number' ||
                        listInputCriteriaBaseType == 'decimal') &&
                      listInputIsOperatorList == false &&
                      listInputIsPickList == false
                    "
                    class="w-full"
                    name="ruleGroupInputValue"
                    v-model="ruleGroupInputValue"
                    v-validate="
                      listInputCriteriaBaseType == 'number'
                        ? 'required|numeric'
                        : listInputCriteriaBaseType == 'decimal'
                        ? 'required|decimal'
                        : 'required'
                    "
                  />

                  <vs-input
                    v-if="listInputCriteriaBaseType == 'datetime'"
                    v-model="ruleGroupDateDays"
                    name="ruleGroupDateDays"
                    v-validate="'required'"
                    class="w-full"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("typeBaseInputValueForm.ruleGroupDateDays")
                  }}</span>
                  <v-select
                    v-if="listInputIsPickList == true && listInputIsOperatorList == false"
                    v-model="ruleGroupInputValue"
                    label="Value"
                    :options="listInputPickListValues"
                    :reduce="(option) => option.Key"
                    name="ruleGroupInputValue"
                    v-validate="'required'"
                    :multiple="listInputIsOperatorList ? true : false"
                  />
                  <v-select
                    v-if="listInputIsPickList == true && listInputIsOperatorList == true"
                    v-model="ruleGroupInputValueMultiple"
                    label="Value"
                    :options="listInputPickListValues"
                    :reduce="(option) => option.Key"
                    name="ruleGroupInputValueMultiple"
                    v-validate="'required'"
                    class='multiple-select'
                    multiple
                  />
                  <v-select
                    v-if="
                      listInputCriteriaBaseType == 'datetime' ||
                      listInputCriteriaBaseType == 'boolean'
                    "
                    v-model="ruleGroupInputValue"
                    label="Title"
                    :reduce="(option) => option.Value"
                    :options="
                      listInputCriteriaBaseType == 'datetime'
                        ? dateTimeOption
                        : booleanOption
                    "
                    name="ruleGroupInputValue"
                    v-validate="'required'"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("typeBaseInputValueForm.ruleGroupInputValue")
                  }}</span>
                </div>
              </div>
               <div class="vx-row find-people" v-if="listInputIsOperatorList && !listInputIsPickList">
               <div class="vx-col  mb-3 w-full ">
                 <div class="flex justify-between flex-wrap items-center">
                    <div >
                      <!-- <span class="vs-input--label pr-2 "> 
                        {{ crmApiName?crmApiName.Crm_Api_Name:'' }} {{ OperatorType?OperatorType.Value:'' }}
                      </span> -->
                      <div  class="w-full mr-2">
                        <vs-input               
                        name="ruleGroupInputValue"
                        v-model="ruleGroupInputValue"
                        v-validate="listInputCriteriaBaseType =='number'?'numeric':listInputCriteriaBaseType =='decimal'?'decimal':''"
                        class="w-full "
                        />     
                            <span class="text-danger text-sm">{{ errors.first('inputValue.ruleGroupInputValue') }}</span>             
                      </div>
                    </div>
                  <div>
                  <div class=" justify-center  w-full  mt-5 ">
                    <vs-button                        
                              title="Plus"
                              color="success"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-arrow-right"
                              class="mr-2 mb-2 "
                              @click="addValueToRule()"
                            ></vs-button>
                            <vs-button
                              title="Delete"
                              color="danger"
                              type="filled"
                              icon-pack="feather"
                              icon="icon-arrow-left"
                              class="mr-0 px-2"
                              @click="removeRuleSelectedValue()"
                            ></vs-button>
                          </div>

                   </div>
                   <div class="mutiselect-custom sm:mt-0 mt-5">
                       <b-form-select
                        v-model="ruleSeleted"
                        :options="ruleGroupInputValueMultiple"
                        :select-size="4"
                      />
                   </div>
                 </div>
               </div>
            </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button
          @click="saveCRMRule()"
          color="primary"
          type="filled"
          class="mr-2"
          >{{ LabelConstant.buttonLabelSave }}</vs-button
        >
        <vs-button
          color="grey"
          @click="cancelCRMRule()"
          type="border"
          class="ml-4 grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    <!-- Confirmation popup to navigate Step 2 -->
    <vs-popup class="warning-popup" :active.sync="confirmToNavigateStep2Popup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="AlertTriangleIcon"></feather-icon>
            </div>
            <h4>
              This will discard your current connection including all data!
            </h4>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          color="grey"
          @click="navigateToCompletedTask(2)"
          type="border"
          class="grey-btn mr-3"
          >{{ LabelConstant.buttonLabelOkay }}
        </vs-button>
        <vs-button
          color="grey"
          @click="confirmToNavigateStep2Popup = false"
          type="border"
          class="grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}
        </vs-button>
      </div>
    </vs-popup>
    <!-- Leave Connection setup wizard confirmation -->
    <vs-popup class="warning-popup" :active.sync="cancelSetupConnectionPopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="AlertTriangleIcon"></feather-icon>
            </div>
            <h4>Are you sure, want to leave connection setup wizard?</h4>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          color="grey"
          @click="leaveSetupConnectionWizard()"
          type="border"
          class="grey-btn mr-3"
          >{{ LabelConstant.buttonLabelYes }}
        </vs-button>
        <vs-button
          color="grey"
          @click="cancelSetupConnectionWizard()"
          type="border"
          class="grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}
        </vs-button>
      </div>
    </vs-popup>
     <!-- Module change warning -->
     <vs-popup class="warning-popup" :active.sync="warningModuleChange">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="AlertTriangleIcon"></feather-icon>
            </div>
            <h4>Are you sure, want to change module ?</h4>
            <p>By changing the module it will reset the rules.</p>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          color="grey"
          @click="yesChangeModule()"
          type="border"
          class="grey-btn mr-3"
          >{{ LabelConstant.buttonLabelYes }}
        </vs-button>
        <vs-button
          color="grey"
          @click="cancelModuleChange()"
          type="border"
          class="grey-btn mr-3"
          >{{ LabelConstant.buttonLabelCancel }}
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { BFormSelect } from "bootstrap-vue";
import { VBTooltip } from "bootstrap-vue";
import { Validator } from "vee-validate";
import InputTag from "vue-input-tag";

const dict = {
  custom: {
    connectionName: {
      required: "Please enter Connection Name.",
      max: "The Connection Name does not contain more than 255 characters.",
    },
    dailyAPICalls: {
      required: "Please enter number of calls.",
      numeric: "Please enter number only.",
    },
    maxRecords: {
      required: "Please enter number of records.",
      numeric: "Please enter number only.",
    },
    allowReadAccessFromIntegrationPartner: {
      required: "Please select the above option.",
    },
    webVisitorOptionVisitThreshold: {
      required: "This field is required.",
      numeric: "Please enter number only.",
    },
    webVisitorOptionVisitPeriod: {
      required: "This field is required.",
      numeric: "Please enter number only.",
    },
    webVisitorNoteOptionVisitThreshold: {
      required: "This field is required.",
      numeric: "Please enter number only.",
    },
    webVisitorNoteOptionVisitPeriod: {
      required: "This field is required.",
      numeric: "Please enter number only.",
    },
    shoppingOptionScoreThreshold: {
      required: "This field is required.",
      numeric: "Please enter number only.",
    },
    shoppingOptionScorePeriod: {
      required: "This field is required.",
      numeric: "Please enter number only.",
    },
    shoppingNoteOptionScoreThreshold: {
      required: "This field is required.",
      numeric: "Please enter number only.",
    },
    shoppingNoteOptionScorePeriod: {
      required: "This field is required.",
      numeric: "Please enter number only.",
    },
    importToCRMAccountOption: {
      required: "This field is required.",
    },
    importToCRMContactOption: {
      required: "This field is required.",
    },
    importToCRMLeadOption: {
      required: "This field is required.",
    },
    importToCRMDuplicateHandlingOption: {
      required: "This field is required.",
    },
    exportToCRMAccountOption: {
      required: "This field is required.",
    },
    exportToCRMContactOption: {
      required: "This field is required.",
    },
    exportToCRMLeadOption: {
      required: "This field is required.",
    },
    formCaptureOptionId: {
      required: "This field is required.",
    },
    formCaptureClassificationId: {
      required: "This field is required.",
    },
    webVisitorOptionId: {
      required: "This field is required.",
    },
    webVisitorClassificationId: {
      required: "This field is required.",
    },
    shoppingOptionId: {
      required: "This field is required.",
    },
    shoppingClassificationId: {
      required: "This field is required.",
    },
    defaultAccountOwnerUserId: {
      required: "This field is required.",
    },
    defaultContactOwnerUserId: {
      required: "This field is required.",
    },
    defaultLeadOwnerUserId: {
      required: "This field is required.",
    },
    lookingQuickExportOptionId:{
      required: "This field is required.",
    },
    lookingQuickExportClassificationId:{
      required: "This field is required.",
    },
    shoppingQuickExportClassificationId:{
      required: "This field is required.",
    },
    shoppingQuickExportOptionId:{
      required: "This field is required.",
    },
    contactQuickExportOptionId:{
      required: "This field is required.",
    },
    contactQuickExportClassificationId:{
      required: "This field is required.",
    },
  },
};

Validator.localize("en", dict);

export default {
  data() {
    return {
      //Wizard Setup
      activeStep: 1,
      completedSteps: [],
      nextStep: 2,
      partialCompletedStep: null,
      cancelSetupConnectionPopup: false,
      isSettingWizard:false,
      warningModuleChange:false,

      //Step2- Establish Connection
      connectionName: null,
      partnerOptions: [],
      integrationPartner: null,
      isCRMConnected: false,
      limitDailyAPICalls: false,
      dailyAPICalls: 100000,
      limitMaxRecords: false,
      maxRecords: 100000,
      allowReadAccessFromIntegrationPartner: false,
      allowWriteAccessIntoIntegrationPartner: false,
      isEventListner: false,
      confirmToNavigateStep2Popup: false,

      //Step3- Import Users
      connectionId: null,
      usersToBeImported: [],
      itemsPerPage: 5,
      sendSetupEmailToNewUsers: false,
      selectedUsersToBeImported: [],
      selectedUsersToBeImportedAll: false,

      //Step4- Define CRM Data to Use
      criteriaList: [],
      accountCRMFieldList: [],
      contactCRMFieldList: [],
      leadCRMFieldList: [],
      conditionMetList: [],
      moduleList: [],
      RecordTagList: [],

      prospectModule:null,
      oldProspectModule:null,

      customerModule:null,
      oldCustomerModule:null,

      competitorsModule:null,
      oldCompetitorsModule:null,

      updateFieldAccount: null,
      updateFieldContact: null,
      updateFieldLead: null,
      updateFieldAccountRadio: "VisualVisitorID-Account",
      updateFieldContactRadio: "VisualVisitorID-Contact",
      updateFieldLeadRadio: "VisualVisitorID-Lead",

      useProspect: false,
      useCustomer: false,
      useCompetitor: false,
      logicalOptions: [
        { Title: "All", Value: "all" },
        { Title: "Any", Value: "any" },
      ],
      filterLogicProspect: null,
      filterLogicCustomer: null,
      filterLogicCompetitor: null,

      options: [
        { Title: "All", Value: "all" },
        { Title: "Any", Value: "any" },
      ],

      editRulePopup: false,
      moduleTypechange:"",

      addRuleParentType: null, //prospect, customer, competitor
      isTypeBaseInputValuePopup: false,
      crmApiName: null,
      operatorType: null,
      inputChildGroupIndex: -1,
      inputRuleGroupIndex: -1,
      listInputCriteriaBaseType: null,
      listInputIsOperatorList: false,
      listInputIsPickList: false,
      listInputPickListValues: [],
      ruleGroupInputValueMultiple: [],
      ruleSeleted:null,
      ruleGroupInputValue: null,
      ruleGroupDateDays: null,
      dateTimeOption: [
        { Title: "days", Value: "days" },
        { Title: "weeks", Value: "weeks" },
        { Title: "months", Value: "months" },
        { Title: "years", Value: "years" },
      ],
      booleanOption: [
        { Title: "true", Value: "true" },
        { Title: "false", Value: "false" },
      ],

      //Step5: Map Account Data Fields
      accountModuleName: null,
      accountVVFieldOptions: [],
      accountCRMAvailableFieldOptions: [],
      accountFieldMapped: [],
      accountErrorMessage: null,
      accountCRMSelected: [],
      keyFieldAccount: null,
      accountMapUpdateBlankFields: false,

      //Step6: Map Contact Data Fields
      contactModuleName: null,
      contactVVFieldOptions: [],
      contactCRMAvailableFieldOptions: [],
      contactFieldMapped: [],
      contactErrorMessage: null,
      contactCRMSelected: [],
      keyFieldContact: null,
      contactMapUpdateBlankFields: false,

      //Step7: Map Lead Data Fields
      leadModuleName: null,
      leadVVFieldOptions: [],
      leadCRMAvailableFieldOptions: [],
      leadFieldMapped: [],
      useContactMapForLead: false,
      leadErrorMessage: null,
      leadCRMSelected: [],
      leadMapUpdateBlankFields: false,
      keyFieldLead: null,

      //Step8: Determine Lead Handling
      accountText: null,
      contactText: null,
      leadText: null,
      accountPluralText: null,
      contactPluralText: null,
      leadPluralText: null,

      userList: [],
      defaultAccountOwnerUserId: null,
      defaultContactOwnerUserId: null,
      defaultLeadOwnerUserId: null,

      importingIntoSystemListOptions: [],
      importToCRMAccountOption: 1,
      importToCRMContactOption: null,
      importToCRMLeadOption: null,

      exportingIntoSystemListOptions: [],
      exportToCRMAccountOption: null,
      exportToCRMContactOption: null,
      exportToCRMLeadOption: null,

      handlingDuplicateListOptions: [],
      importToCRMDuplicateHandlingOption: null,

      doNotImportListOptions: [],
      doNotImport: [],

      importNotificationEmail : "",
      exportNotificationEmail:"",

      allowQuickExportLooking: false,
      formCaptureOptionId: null,
      classifications: [],
      recordListOptions:[],
      crmNameToDisplay:null,
      lookingQuickExportOptionId:null,
      lookingQuickExportClassificationId:null,

      shoppingQuickExportClassificationId:null,
      shoppingQuickExportOptionId:null,

      contactQuickExportOptionId:null,
      contactQuickExportClassificationId:null,

      formCaptureClassificationId: null,
      formCaptureInsertNote: false,

      webVisitorOptionEnabled: false,
      webVisitorOptionVisitThreshold: null,
      webVisitorOptionVisitPeriod: null,
      webVisitorOptionId: null,
      webVisitorClassificationId: null,
      webVisitorInsertNote: false,
      webVisitorNoteOptionVisitThreshold: null,
      webVisitorNoteOptionVisitPeriod: null,

      allowQuickExportShopping: false,
      shoppingOptionEnabled: false,
      shoppingOptionScoreThreshold: null,
      shoppingOptionScorePeriod: null,
      shoppingOptionId: null,
      shoppingClassificationId: null,
      shoppingInsertNote: false,
      shoppingNoteOptionScoreThreshold: null,
      shoppingNoteOptionScorePeriod: null,

      allowQuickExportContact: false,
      callTrackingInsertNote: false,
    };
  },
  created() {
    this.getPartnerList();
  },
  watch: {
    prospectModule(val){
      if(!this.oldProspectModule){
        this.oldProspectModule = val;
      }
    },
    customerModule(val){
      if(!this.oldCustomerModule){
        this.oldCustomerModule = val;
      }
    },
    competitorsModule(val){
      if(!this.oldCompetitorsModule){
        this.oldCompetitorsModule = val;
      }
    },
  },
  mounted() {
    let uid = this.$route.params.id;
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    if (token[uid].completeStep) {
      this.activeStep = token[uid].completeStep;
      this.nextStep = this.activeStep + 1;
      this.connectionId = token[uid].wizardConnectionId;
      for (let index = 1; index < token[uid].completeStep; index++) {
        this.completedSteps.push(index);
        this.isSettingWizard = true;
      }
      this.getDataOfCompletedSteps();
    }

    var menuitem = document.getElementsByClassName("vs-sidebar-group ");
    for (var i = 0; i < menuitem.length; i++) {
      var arrow = menuitem[i].getElementsByClassName(
        "feather-icon select-none relative feather-grp-header-arrow"
      );
      var text = menuitem[i].getElementsByClassName(
        "truncate mr-3 select-none"
      )[0].innerText;
      var uls = menuitem[i].getElementsByTagName("ul");
      if (text == "Configuration") {
        menuitem[i].className += " vs-sidebar-group-open";
        arrow[0].className += " rotate90";
        uls[0].style.maxHeight = "none";

        for (var j = 0; j < uls.length; j++) {
          var lis = uls[j].getElementsByTagName("li");
          for (var k = 0; k < lis.length; k++) {
            var textInner = lis[k].innerText;
            var a = lis[k].getElementsByTagName("a");
            if (textInner == "Integration Partner") {
              a[0].className += " router-link-exact-active router-link-active";
            }
          }
        }
      }
    }

    var that = this;
    window.addEventListener("focus", checkCRMConnectionStatus);

    function checkCRMConnectionStatus() {
      if (that.isEventListner) {
        that.getCRMIntegrationStatus();
      }
      that.isEventListner = false;
    }
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      let uid = this.$route.params.id;
      let VVtoken = localStorage.getItem("VVtoken");
      let token = JSON.parse(VVtoken);
      if (token[uid].hasOwnProperty("completeStep")) {
        delete token[uid].completeStep;
        delete token[uid].wizardConnectionId;
        localStorage.setItem("VVtoken", JSON.stringify(token));
      }

      let menuitem = [];
      menuitem = document.getElementsByClassName("vs-sidebar-group ");
      for (var i = 0; i < menuitem.length; i++) {
        if (menuitem[i].classList.contains("vs-sidebar-group-open")) {
          let text = menuitem[i].getElementsByClassName(
            "truncate mr-3 select-none"
          )[0].innerText;
          let uls = menuitem[i].getElementsByTagName("ul");
          let arrow = menuitem[i].getElementsByClassName(
            "feather-icon select-none relative feather-grp-header-arrow"
          );
          let det = to.path;
          det = det.match("/configuration");
          if (text == "Configuration" && !det) {
            uls[0].style.maxHeight = 0;
            arrow[0].classList.remove("rotate90");
            menuitem[i].classList.remove("vs-sidebar-group-open");

            for (var j = 0; j < uls.length; j++) {
              var lis = uls[j].getElementsByTagName("li");
              for (var k = 0; k < lis.length; k++) {
                var textInner = lis[k].innerText;
                var a = lis[k].getElementsByTagName("a");
                if (textInner == "Integration Partner") {
                  a[0].className = "";
                }
              }
            }
          }
        }
      }
    }, 300);
    next();
  },
  components: {
    "v-select": vSelect,
    VBTooltip,
    InputTag,
    BFormSelect
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    isFirstStepDisabled() {
      if (this.activeStep != 2) {
        return false;
      } else {
        if (this.isCRMConnected) {
          return false;
        } else {
          return true;
        }
      }
    },
  },
  methods: {
    leaveSetupConnectionWizard() {
      this.isSettingWizard = false;
      this.cancelSetupConnectionPopup = false;
      setTimeout(() => {
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        if (token[uid].hasOwnProperty("completeStep")) {
          delete token[uid].completeStep;
          delete token[uid].wizardConnectionId;
          localStorage.setItem("VVtoken", JSON.stringify(token));
        }
        this.resetSettings();
        this.$router.push({
          path: "/configuration/integrationpartners/uid/" + uid,
        });
      }, 100);
    },
    cancelSetupConnectionWizard() {
      this.cancelSetupConnectionPopup = false;
    },
    validateStep() {
      if (this.activeStep == 4) {
        let result = this.validateFourthStep();
        if (result.status) {
          this.saveSettings();
        } else {
          this.editRulePopup = true;
        }
      } else if (this.activeStep == 5) {
        let result = this.validateFifthStep();
        if (result && this.keyFieldAccount) {
          this.saveSettings();
        } else {
          if (!this.keyFieldAccount) {
            this.$vs.notify({
              title: "Erorr",
              text: "Please select one field as a id field",
              color: "danger",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          }
        }
      } else if (this.activeStep == 6) {
        let result = this.validateSixthStep();
        if (result && this.keyFieldContact) {
          this.saveSettings();
        } else {
          if (!this.keyFieldContact) {
            this.$vs.notify({
              title: "Erorr",
              text: "Please select one field as a id field",
              color: "danger",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          }
        }
      } else if (this.activeStep == 7) {
        let result = this.validateSeventhStep();
        if (result && this.keyFieldLead) {
          this.saveSettings();
        } else {
          if (this.keyFieldLead) {
            this.$vs.notify({
              title: "Erorr",
              text: "Please select one field as a id field",
              color: "danger",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          }
        }
      } else if (this.activeStep == 8) {
        this.$validator.validateAll("leadHandlingForm").then((result) => {
          if (result) {
            this.saveSettings();
          }
        });
      } else {
        this.saveSettings();
      }
    },
    validateFourthStep() {
      if (this.useProspect) {
          for (
            let j = 0;
            j < this.filterLogicProspect.Rule_Group.length;
            j++
          ) {
            if (
              !this.filterLogicProspect.Rule_Group[j].Crm_Api_Name ||
              !this.filterLogicProspect.Rule_Group[j].Operator_Id ||
              !this.filterLogicProspect.Rule_Group[j].Values
            ) {
              return { status: false };
            }
          }
      }
      if (this.useCustomer) {
          for (
            let j = 0;
            j < this.filterLogicCustomer.Rule_Group.length;
            j++
          ) {
            if (
              !this.filterLogicCustomer.Rule_Group[j]
                .Crm_Api_Name ||
              !this.filterLogicCustomer.Rule_Group[j]
                .Operator_Id ||
              !this.filterLogicCustomer.Rule_Group[j].Values
            ) {
              return { status: false };
            }
          }
      }
      if (this.useCompetitor) {
     
          for (
            let j = 0;
            j <
            this.filterLogicCompetitor.Rule_Group.length;
            j++
          ) {
            if (
              !this.filterLogicCompetitor.Rule_Group[j].Crm_Api_Name ||
              !this.filterLogicCompetitor.Rule_Group[j].Operator_Id ||
              !this.filterLogicCompetitor.Rule_Group[j].Values
            ) {
              return { status: false };
            }
          }
        }
      return { status: true };
    },
    validateFifthStep() {
      let errorArray = {
        ValidationRule: [],
        vvField: [],
      };
      for (let i = 0; i < this.accountCRMAvailableFieldOptions.length; i++) {
        if (
          this.accountCRMAvailableFieldOptions[i].Required_Field &&
          !this.accountCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id
        ) {
          errorArray.vvField.push(i);
        }
        if (
          this.accountCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id
        ) {
          if (
            !this.accountCRMAvailableFieldOptions[i].CurrentRowValues
              .Validation_Rule_Id
          ) {
            // return {status:false, data: {index: i, column: 3, message: 'This field is required'}}
            errorArray.ValidationRule.push(i);
          }
        }
      }
      this.accountErrorMessage = errorArray;
      if (errorArray.ValidationRule.length || errorArray.vvField.length) {
        return false;
      } else {
        return true;
      }
      // return {status:true}
    },
    validateSixthStep() {
      let errorArray = {
        ValidationRule: [],
        vvField: [],
      };
      for (let i = 0; i < this.contactCRMAvailableFieldOptions.length; i++) {
        if (
          this.contactCRMAvailableFieldOptions[i].Required_Field &&
          !this.contactCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id
        ) {
          errorArray.vvField.push(i);
        }
        if (
          this.contactCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id
        ) {
          if (
            !this.contactCRMAvailableFieldOptions[i].CurrentRowValues
              .Validation_Rule_Id
          ) {
            // return {status:false, data: {index: i, column: 3, message: 'This field is required'}}
            errorArray.ValidationRule.push(i);
          }
        }
      }
      this.contactErrorMessage = errorArray;
      if (errorArray.ValidationRule.length || errorArray.vvField.length) {
        return false;
      } else {
        return true;
      }
      // return {status:true}
    },
    validateSeventhStep() {
      let errorArray = {
        ValidationRule: [],
        vvField: [],
      };
      for (let i = 0; i < this.leadCRMAvailableFieldOptions.length; i++) {
        if (
          this.leadCRMAvailableFieldOptions[i].Required_Field &&
          !this.leadCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id
        ) {
          errorArray.vvField.push(i);
        }
        if (this.leadCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id) {
          if (
            !this.leadCRMAvailableFieldOptions[i].CurrentRowValues
              .Validation_Rule_Id
          ) {
            // return {status:false, data: {index: i, column: 3, message: 'This field is required'}}
            errorArray.ValidationRule.push(i);
          }
        }
      }
      this.leadErrorMessage = errorArray;
      if (errorArray.ValidationRule.length || errorArray.vvField.length) {
        return false;
      } else {
        return true;
      }
      // return {status:true}
    },
    saveSettings() {
      if (this.activeStep == 1) {
        this.stepProgressOnSave();
      } else if (this.activeStep == 2) {
        this.secondStepDone();
      } else if (this.activeStep == 3) {
        this.thirdStepSaveSettings();
      } else if (this.activeStep == 4) {
        this.fourthStepSaveSettings();
      } else if (this.activeStep == 5) {
        this.fifthStepSaveSettings();
      } else if (this.activeStep == 6) {
        this.sixthStepSaveSettings();
      } else if (this.activeStep == 7) {
        this.seventhStepSaveSettings();
      } else if (this.activeStep == 8) {
        this.eighthStepSaveSettings();
      } else {
        let uid = this.$route.params.id;
        this.$router.push({
          path: "/configuration/integrationpartners/uid/" + uid,
        });
      }
    },
    stepProgressOnSave() {
      if (!this.completedSteps.includes(this.activeStep)) {
        this.completedSteps.push(this.activeStep);
        this.activeStep = this.nextStep;
        this.nextStep++;
      } else {
        this.activeStep = this.partialCompletedStep;
      }
      var elItems = document
        .getElementsByClassName("setup-wizard")[0]
        .getElementsByTagName("li");
      let i = 1;
      for (let elItem of elItems) {
        if (i == this.activeStep) {
          elItem.classList.add("active");
          elItem.classList.add("blue-arrow");
        } else {
          if (this.completedSteps.includes(i)) {
            elItem.classList.add("checked");
            elItem.classList.add("blue-line");
            elItem.classList.remove("active");
            elItem.classList.remove("blue-arrow");
          }
        }
        i++;
      }
    },
    takeConfirmationToNavigateStep2() {
      this.confirmToNavigateStep2Popup = true;
    },
    cleanAllDataBeforeMoveToStep2() {
      //Wizard Setup
      this.activeStep = 2;
      this.completedSteps = [1];
      this.nextStep = 3;
      this.partialCompletedStep = null;
      this.connectionName = null;

      //Step2- Establish Connection
      this.integrationPartner = null;
      this.isCRMConnected = false;
      this.limitDailyAPICalls = false;
      this.dailyAPICalls = 100000;
      this.limitMaxRecords = false;
      this.maxRecords = 100000;
      this.allowReadAccessFromIntegrationPartner = false;
      this.allowWriteAccessIntoIntegrationPartner = false;

      //Step3- Import Users
      this.connectionId = null;
      this.usersToBeImported = [];
      this.itemsPerPage = 5;
      this.sendSetupEmailToNewUsers = false;
      this.selectedUsersToBeImported = [];

      //Step4- Define CRM Data to Use
      this.criteriaList = [];
      this.accountCRMFieldList = [];
      this.contactCRMFieldList = [];
      this.leadCRMFieldList = [];

      this.updateFieldAccount = null;
      this.updateFieldContact = null;
      this.updateFieldLead = null;
      this.updateFieldAccountRadio = "VisualVisitorID-Account";
      this.updateFieldContactRadio = "VisualVisitorID-Contact";
      this.updateFieldLeadRadio = "VisualVisitorID-Lead";

      this.useProspect = false;
      this.useCustomer = false;
      this.useCompetitor = false;

      this.filterLogicProspect = null;
      this.filterLogicCustomer = null;
      this.filterLogicCompetitor = null;

      this.addRuleParentType = null;
      this.isTypeBaseInputValuePopup = false;
      this.crmApiName = null;
      this.operatorType = null;
      this.inputChildGroupIndex = -1;
      this.inputRuleGroupIndex = -1;
      this.listInputCriteriaBaseType = null;
      this.listInputIsOperatorList = false;
      this.listInputIsPickList = false;
      this.listInputPickListValues = [];
      this.ruleGroupInputValueMultiple = [];
      this.ruleSeleted = null;
      this.ruleGroupInputValue = null;
      this.ruleGroupDateDays = null;

      //Step5: Map Account Data Fields
      (this.accountModuleName = null),
        (this.accountVVFieldOptions = []),
        (this.accountCRMAvailableFieldOptions = []),
        (this.accountFieldMapped = []),
        (this.accountErrorMessage = null),
        (this.accountCRMSelected = []),
        (this.keyFieldAccount = null),
        //Step6: Map Contact Data Fields
        (this.contactModuleName = null),
        (this.contactVVFieldOptions = []),
        (this.contactCRMAvailableFieldOptions = []),
        (this.contactFieldMapped = []),
        (this.contactErrorMessage = null),
        (this.contactCRMSelected = []),
        (this.keyFieldContact = null),
        //Step7: Map Lead Data Fields
        (this.leadModuleName = null),
        (this.leadVVFieldOptions = []),
        (this.leadCRMAvailableFieldOptions = []),
        (this.leadFieldMapped = []),
        (this.useContactMapForLead = false),
        (this.leadErrorMessage = null),
        (this.leadCRMSelected = []),
        (this.keyFieldLead = null),
        //Step8: Determine Lead Handling
        (this.accountText = null);
      this.contactText = null;
      this.leadText = null;
      this.accountPluralText = null;
      this.contactPluralText = null;
      this.leadPluralText = null;

      this.userList = [];
      this.defaultAccountOwnerUserId = null;
      this.defaultContactOwnerUserId = null;
      this.defaultLeadOwnerUserId = null;

      this.importingIntoSystemListOptions = [];
      this.importToCRMAccountOption = null;
      this.importToCRMContactOption = null;
      this.importToCRMLeadOption = null;

      this.exportingIntoSystemListOptions = [];
      this.exportToCRMAccountOption = null;
      this.exportToCRMContactOption = null;
      this.exportToCRMLeadOption = null;
      this.importNotificationEmail = "";
      this.exportNotificationEmail = "";

      this.handlingDuplicateListOptions = [];
      this.importToCRMDuplicateHandlingOption = null;

      this.doNotImportListOptions = [];
      this.doNotImport = [];

      this.allowQuickExportLooking = false;
      this.formCaptureOptionId = null;
      this.classifications = [];
      this.recordListOptions = [];
      this.crmNameToDisplay = null;

      this.lookingQuickExportOptionId = null;
      this.lookingQuickExportClassificationId =null;

      this.shoppingQuickExportClassificationId = null;
      this.shoppingQuickExportOptionId = null; 
      
      this.contactQuickExportOptionId = null;
      this.contactQuickExportClassificationId = null;

      this.formCaptureClassificationId = null;
      this.formCaptureInsertNote = false;

      this.webVisitorOptionEnabled = false;
      this.webVisitorOptionVisitThreshold = null;
      this.webVisitorOptionVisitPeriod = null;
      this.webVisitorOptionId = null;
      this.webVisitorClassificationId = null;
      this.webVisitorInsertNote = false;
      this.webVisitorNoteOptionVisitThreshold = null;
      this.webVisitorNoteOptionVisitPeriod = null;

      this.allowQuickExportShopping = false;
      this.shoppingOptionEnabled = false;
      this.shoppingOptionScoreThreshold = null;
      this.shoppingOptionScorePeriod = null;
      this.shoppingOptionId = null;
      this.shoppingClassificationId = null;
      this.shoppingInsertNote = false;
      this.shoppingNoteOptionScoreThreshold = null;
      this.shoppingNoteOptionScorePeriod = null;

      this.allowQuickExportContact = false;
      this.callTrackingInsertNote = false;

      setTimeout(() => {
        this.errors.clear("establishConnectionForm");
        this.errors.clear("leadHandlingForm");
        this.errors.clear("typeBaseInputValueForm");
      }, 100);

      var elItems = document
        .getElementsByClassName("setup-wizard")[0]
        .getElementsByTagName("li");
      let i = 1;
      for (let elItem of elItems) {
        if (i == this.activeStep) {
          elItem.classList.remove("checked");
          elItem.classList.remove("blue-line");
          elItem.classList.add("active");
          elItem.classList.add("blue-arrow");
        }
        if (i != 1 && i != this.activeStep) {
          elItem.classList.remove("checked");
          elItem.classList.remove("blue-line");
          elItem.classList.remove("active");
          elItem.classList.remove("blue-arrow");
        }
        i++;
      }

      this.confirmToNavigateStep2Popup = false;
    },
    async resetSettings() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/IntegrationWizardConnection/ResetSettings?connection_Id=" +
            this.connectionId
        )
        .then(() => {
          this.cleanAllDataBeforeMoveToStep2();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    navigateToCompletedTask(activeStep) {
      if (activeStep == 2) {
        this.resetSettings();
      }
      if (
        this.completedSteps.includes(activeStep) ||
        this.partialCompletedStep == activeStep
      ) {
        this.partialCompletedStep = Math.max(...this.completedSteps) + 1;
        this.activeStep = activeStep;
        // this.nextStep = activeStep + 1
        var elItems = document
          .getElementsByClassName("setup-wizard")[0]
          .getElementsByTagName("li");
        let i = 1;
        for (let elItem of elItems) {
          if (i == this.activeStep) {
            elItem.classList.remove("checked");
            elItem.classList.add("active");
          } else {
            if (this.completedSteps.includes(i)) {
              elItem.classList.remove("active");
              elItem.classList.add("checked");
            }
          }
          i++;
        }
      }
    },
    //Step2: Establish Connection
    getPartnerList() {
      this.$vs.loading();
      this.axios
        .get("/ws/IntegrationWizardConnection/GetPartnerList")
        .then((response) => {
          let data = response.data;
          this.partnerOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    async secondStepSaveSettings() {
      this.$validator.validateAll("establishConnectionForm").then((result) => {
        if (result) {
          let secondStepSaveSettingsParams = {
            Connection_Name: this.connectionName,
            Integration_Partner_ID: this.integrationPartner,
            Limit_Daily_API: this.limitDailyAPICalls,
            Limit_Daily_API_Threshold: this.dailyAPICalls,
            Limit_Max_Records: this.limitMaxRecords,
            Limit_Max_Records_Threshold: this.maxRecords,
            Allow_Read_From_CRM: this.allowReadAccessFromIntegrationPartner,
            Allow_Write_To_CRM: this.allowWriteAccessIntoIntegrationPartner,
          };
          this.$vs.loading();
          this.axios
            .post(
              "/ws/IntegrationWizardConnection/SaveSettings",
              secondStepSaveSettingsParams
            )
            .then((response) => {
              let data = response.data;
              if(data.Transaction_Successful){

              this.connectionId = data.Connection_Id;
              this.$vs.notify({
                title: "Success",
                text: response.data.Message,
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.getExternalCRMLink();
              } else {
                 this.$vs.notify({
                title: "Error",
                text: response.data.Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    async getExternalCRMLink() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/IntegrationPartnerConnection/GetExternalLinkForAuthUpdate?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.isEventListner = true;
          window
            .open(
              data,
              +"Zoho Account",
              "toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100"
            )
            .focus();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getCRMIntegrationStatus() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/IntegrationPartnerConnection/GetCRMIntegrationStatus?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          if (data.Transaction_Successful) {
            this.isCRMConnected = true;
            this.$vs.notify({
              title: "Success",
              text: "Connection Established!",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Connection Failed!",
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    diconnectCRM() {
      this.$vs.loading();
      this.axios
        .get(
          "/ws/IntegrationPartnerConnection/DeleteCRMIntegrationConnection?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          if (data.Transaction_Successful) {
            this.isCRMConnected = false;
            this.$vs.notify({
              title: "Success",
              text: "Connection removed!",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Connection not removed!",
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    secondStepDone() {
      this.$validator.validateAll("establishConnectionForm").then((result) => {
        if (result) {
          this.stepProgressOnSave();
          this.thirdStepGetUserList();
        }
      });
    },
    //Step3: Import Users
    thirdStepGetUserList() {
      this.$vs.loading();
      this.axios
        .get(
          "/ws/IntegrationWizardUsers/GetUserList?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.usersToBeImported = data;
          data.forEach((element) => {
            if (element.User_Exists) {
              this.selectedUsersToBeImported.push(element.User_Id);
            }
          });
          if (this.selectedUsersToBeImported.length == data.length) {
            this.selectedUsersToBeImportedAll = true;
          } else {
            this.selectedUsersToBeImportedAll = false;
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    async thirdStepSaveSettings() {
      let userIds = [];
      for (let i = 0; i < this.selectedUsersToBeImported.length; i++) {
        userIds.push(this.selectedUsersToBeImported[i].User_Id);
      }
      let thirdStepSaveSettingsParams = {
        Connection_Id: this.connectionId,
        Send_Setup_Email: this.sendSetupEmailToNewUsers,
        User_Ids: this.selectedUsersToBeImported,
      };
      this.$vs.loading();
      await this.axios
        .post(
          "/ws/IntegrationWizardUsers/SaveSettings",
          thirdStepSaveSettingsParams
        )
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.stepProgressOnSave();
          if(!this.completedSteps.includes(4)){
            this.fourthStepGetAvailableFields();
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Step4: Define CRM Data to Use
    async fourthStepGetAvailableFields() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/IntegrationWizardCRMDataFilter/GetAvailableFields?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.accountCRMFieldList = data.Criteria_List_Account;
          this.contactCRMFieldList = data.Criteria_List_Contact;
          this.leadCRMFieldList = data.Criteria_List_Lead;
          this.conditionMetList = data.Conditions_Met_List;
          this.moduleList = data.Module_List;
          this.RecordTagList = data.Record_Tag_List;
          this.filterLogicProspect = {
            Group_Condition_Met: "all",
            Rule_Group: [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
            }],
          };
          /*for (
            let i = 0;
            i < this.filterLogicProspect.Child_Logic_Group.length;
            i++
          ) {
            for (
              let j = 0;
              j <
              this.filterLogicProspect.Child_Logic_Group[i].Rule_Group.length;
              j++
            ) {
              for (let k = 0; k < this.criteriaList.length; k++) {
                if (
                  this.filterLogicProspect.Child_Logic_Group[i].Rule_Group[j]
                    .Criteria_Name == this.criteriaList[k].Criteria_Name
                ) {
                  this.filterLogicProspect.Child_Logic_Group[i].Rule_Group[
                    j
                  ].Criteria_Name = this.criteriaList[k];
                }
                for (
                  let l = 0;
                  l < this.criteriaList[j].Operator_Type.length;
                  l++
                ) {
                  if (
                    this.filterLogicProspect.Child_Logic_Group[i].Rule_Group[j]
                      .Operator_Id == this.criteriaList[j].Operator_Type[l].Key
                  ) {
                    this.filterLogicProspect.Child_Logic_Group[i].Rule_Group[
                      j
                    ].Operator_Id = this.criteriaList[j].Operator_Type[l];
                  }
                }
              }
            }
          }*/
          this.filterLogicCustomer = {
            Group_Condition_Met: "all",
            Rule_Group: [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
            }],
          };
          /*for (
            let i = 0;
            i < this.filterLogicCustomer.Child_Logic_Group.length;
            i++
          ) {
            for (
              let j = 0;
              j <
              this.filterLogicCustomer.Child_Logic_Group[i].Rule_Group.length;
              j++
            ) {
              for (let k = 0; k < this.criteriaList.length; k++) {
                if (
                  this.filterLogicCustomer.Child_Logic_Group[i].Rule_Group[j]
                    .Criteria_Name == this.criteriaList[k].Criteria_Name
                ) {
                  this.filterLogicCustomer.Child_Logic_Group[i].Rule_Group[
                    j
                  ].Criteria_Name = this.criteriaList[k];
                }
                for (
                  let l = 0;
                  l < this.criteriaList[j].Operator_Type.length;
                  l++
                ) {
                  if (
                    this.filterLogicCustomer.Child_Logic_Group[i].Rule_Group[j]
                      .Operator_Id == this.criteriaList[j].Operator_Type[l].Key
                  ) {
                    this.filterLogicCustomer.Child_Logic_Group[i].Rule_Group[
                      j
                    ].Operator_Id = this.criteriaList[j].Operator_Type[l];
                  }
                }
              }
            }
          }*/
          this.filterLogicCompetitor = {
            Group_Condition_Met: "all",
            Rule_Group: [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
            }],
          };
          /* for (
            let i = 0;
            i < this.filterLogicCompetitor.Child_Logic_Group.length;
            i++
          ) {
            for (
              let j = 0;
              j <
              this.filterLogicCompetitor.Child_Logic_Group[i].Rule_Group.length;
              j++
            ) {
              for (let k = 0; k < this.criteriaList.length; k++) {
                if (
                  this.filterLogicCompetitor.Child_Logic_Group[i].Rule_Group[j]
                    .Criteria_Name == this.criteriaList[k].Criteria_Name
                ) {
                  this.filterLogicCompetitor.Child_Logic_Group[i].Rule_Group[
                    j
                  ].Criteria_Name = this.criteriaList[k];
                }
                for (
                  let l = 0;
                  l < this.criteriaList[j].Operator_Type.length;
                  l++
                ) {
                  if (
                    this.filterLogicCompetitor.Child_Logic_Group[i].Rule_Group[
                      j
                    ].Operator_Id == this.criteriaList[j].Operator_Type[l].Key
                  ) {
                    this.filterLogicCompetitor.Child_Logic_Group[i].Rule_Group[
                      j
                    ].Operator_Id = this.criteriaList[j].Operator_Type[l];
                  }
                }
              }
            }
           }*/
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    fourthStepGetSettings(){
      this.$vs.loading();
      this.axios.get("/ws/IntegrationWizardCRMDataFilter/GetSettings?connection_Id="+this.connectionId).then((response) => {
        let data = response.data;
        this.useProspect = data.Use_Prospect,
        this.useCustomer = data.Use_Customer,
        this.useCompetitor = data.Use_Competitor,
        this.prospectModule = data.Prospect_Module_Id,
        this.customerModule = data.Customer_Module_Id,
        this.competitorsModule = data.Competitor_Module_Id,
        this.filterLogicProspect = data.Filter_Logic_Prospect
        let prospectFieldList = data.Prospect_Module_Id == 1?this.accountCRMFieldList:data.Prospect_Module_Id == 2?this.contactCRMFieldList:data.Prospect_Module_Id == 3?this.leadCRMFieldList:[];
        if(this.filterLogicProspect){
          for (let j = 0; j < this.filterLogicProspect.Rule_Group.length; j++) {
               this.filterLogicProspect.Rule_Group[j].Values = this.filterLogicProspect.Rule_Group[j].Values?this.filterLogicProspect.Rule_Group[j].Values.join():null;  
            for (let k = 0; k < prospectFieldList.length; k++) {
              if(this.filterLogicProspect.Rule_Group[j].Crm_Api_Name == prospectFieldList[k].Crm_Api_Name){
                this.filterLogicProspect.Rule_Group[j].Crm_Api_Name = prospectFieldList[k]
              }
              for (let l = 0; l < prospectFieldList[j].Operator_Type.length; l++) {
                if(this.filterLogicProspect.Rule_Group[j].Operator_Id == prospectFieldList[j].Operator_Type[l].Key){
                  this.filterLogicProspect.Rule_Group[j].Operator_Id = prospectFieldList[j].Operator_Type[l]
                }
              }
            }
          }
        } else {
          this.filterLogicProspect = {
            Group_Condition_Met: "all",
            Rule_Group: [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
            }],
          };
        }
        let customerFieldList = data.Customer_Module_Id == 1?this.accountCRMFieldList:data.Customer_Module_Id == 2?this.contactCRMFieldList:data.Customer_Module_Id == 3?this.leadCRMFieldList:[];
        this.filterLogicCustomer = data.Filter_Logic_Customer
        if(this.filterLogicCustomer){
          for (let j = 0; j < this.filterLogicCustomer.Rule_Group.length; j++) {
            this.filterLogicCustomer.Rule_Group[j].Values = this.filterLogicCustomer.Rule_Group[j].Values?this.filterLogicCustomer.Rule_Group[j].Values.join():null;  
            for (let k = 0; k < customerFieldList.length; k++) {
              if(this.filterLogicCustomer.Rule_Group[j].Crm_Api_Name == customerFieldList[k].Crm_Api_Name){
                this.filterLogicCustomer.Rule_Group[j].Crm_Api_Name = customerFieldList[k]
              }
              for (let l = 0; l < customerFieldList[j].Operator_Type.length; l++) {
                if(this.filterLogicCustomer.Rule_Group[j].Operator_Id == customerFieldList[j].Operator_Type[l].Key){
                  this.filterLogicCustomer.Rule_Group[j].Operator_Id = customerFieldList[j].Operator_Type[l]
                }
              }
            }
          }
             } else {
                 this.filterLogicCustomer = {
            Group_Condition_Met: "all",
            Rule_Group: [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
            }],
          };
             }

        let competitorFieldList = data.Competitor_Module_Id == 1?this.accountCRMFieldList:data.Competitor_Module_Id == 2?this.contactCRMFieldList:data.Competitor_Module_Id == 3?this.leadCRMFieldList:[];
        this.filterLogicCompetitor = data.Filter_Logic_Competitor
        if(this.filterLogicCompetitor){
          for (let j = 0; j < this.filterLogicCompetitor.Rule_Group.length; j++) {
              this.filterLogicCompetitor.Rule_Group[j].Values = this.filterLogicCompetitor.Rule_Group[j].Values?this.filterLogicCompetitor.Rule_Group[j].Values.join():null;  
            for (let k = 0; k < competitorFieldList.length; k++) {
              if(this.filterLogicCompetitor.Rule_Group[j].Crm_Api_Name == competitorFieldList[k].Crm_Api_Name){
                this.filterLogicCompetitor.Rule_Group[j].Crm_Api_Name = competitorFieldList[k]
              }
              for (let l = 0; l < competitorFieldList[j].Operator_Type.length; l++) {
                if(this.filterLogicCompetitor.Rule_Group[j].Operator_Id == competitorFieldList[j].Operator_Type[l].Key){
                  this.filterLogicCompetitor.Rule_Group[j].Operator_Id = competitorFieldList[j].Operator_Type[l]
                }
              }
            }
          }
        } else {
           this.filterLogicCompetitor = {
            Group_Condition_Met: "all",
            Rule_Group: [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
            }],
          };
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    addRule(type, index) {
      if (type == "prospect") {
        this.filterLogicProspect.Rule_Group.splice(
          index + 1,
          0,
          {
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
          }
        );
      } else if (type == "customer") {
        this.filterLogicCustomer.Rule_Group.splice(
          index + 1,
          0,
          {
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
          }
        );
      } else {
        this.filterLogicCompetitor.Rule_Group.splice(
          index + 1,
          0,
          {
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
          }
        );
      }
    },
    deleteRule(type, index) {
      if (type == "prospect") {
          this.filterLogicProspect.Rule_Group.splice(index, 1);
      } else if (type == "customer") {
          this.filterLogicCustomer.Rule_Group.splice(index, 1); 
      } else {
          this.filterLogicCompetitor.Rule_Group.splice(index, 1);
      }
    },
    addChildLogicGroup(type) {
      if (type == "prospect") {
        this.filterLogicProspect.Child_Logic_Group.push({
          Child_Logic_Group: null,
          Group_Condition_Met: "all",
          Rule_Group: [
            {
              Criteria_Name: "",
              Operator_Id: "",
              Value: null,
            },
          ],
        });
      } else if (type == "customer") {
        this.filterLogicCustomer.Child_Logic_Group.push({
          Child_Logic_Group: null,
          Group_Condition_Met: "all",
          Rule_Group: [
            {
              Criteria_Name: "",
              Operator_Id: "",
              Value: null,
            },
          ],
        });
      } else {
        this.filterLogicCompetitor.Child_Logic_Group.push({
          Child_Logic_Group: null,
          Group_Condition_Met: "all",
          Rule_Group: [
            {
              Criteria_Name: "",
              Operator_Id: "",
              Value: null,
            },
          ],
        });
      }
    },
    getAvailableValues(
      Crm_Api_Name,
      operatorId,
      addRuleParentType,
      innerIndex
    ) {
      if (Crm_Api_Name && operatorId) {
        this.$vs.loading();
        let input = {
          Connection_Id: this.connectionId,
          Crm_Api_Name: Crm_Api_Name.Crm_Api_Name,
          Crm_Display_Name:Crm_Api_Name.Crm_Display_Name,
          Module_Id:addRuleParentType == "prospect"? this.prospectModule:addRuleParentType == "customer"? this.customerModule:this.competitorsModule,
          Operator_Id: operatorId.Key,
        };
        this.axios
          .post(
            "/ws/IntegrationPartnerCRMDataFilterDetailsValue/GetAvailableValues",
            input
          )
          .then((response) => {
            let data = response.data;
            this.listInputCriteriaBaseType = data.Criteria_Base_Type;
            this.listInputIsOperatorList = data.Is_Operator_List;
            this.listInputIsPickList = data.Is_Pick_List;
            this.listInputPickListValues = data.Pick_List_Values;
            this.crmApiName = Crm_Api_Name;
            this.operatorType = operatorId;
              (this.inputRuleGroupIndex = innerIndex),
              (this.isTypeBaseInputValuePopup = true);
            this.addRuleParentType = addRuleParentType;
            this.$vs.loading.close();
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.showError(e);
            this.$vs.notify({
              title: "Erorr",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          });
      } else {
        this.editRulePopup = true;
      }
    },
    cancelCRMRule() {
      this.isTypeBaseInputValuePopup = false;
      setTimeout(() => {
        this.errors.clear("typeBaseInputValueForm");
      }, 20);
      this.addRuleParentType = null;
      this.inputChildGroupIndex = -1;
      this.inputRuleGroupIndex = -1;
      this.listInputCriteriaBaseType = null;
      this.listInputIsOperatorList = false;
      this.listInputIsPickList = false;
      this.listInputPickListValues = null;
      this.crmApiName = null;
      this.operatorType = null;
      this.ruleGroupInputValue = null;
      this.ruleGroupInputValueMultiple = [];
      this.ruleSeleted = null;
      this.ruleGroupDateDays = null;
    },
    saveCRMRule() {
      this.$validator.validateAll("typeBaseInputValueForm").then((result) => {
        if (result) {
          if (this.addRuleParentType == "prospect") {
                this.filterLogicProspect.Rule_Group.forEach((el, index) => {
                  if (index == this.inputRuleGroupIndex) {
                    if (this.listInputCriteriaBaseType == "datetime") {
                      el.Values =
                        this.ruleGroupDateDays + " " + this.ruleGroupInputValue;
                    } else if (this.listInputCriteriaBaseType == "boolean") {
                      el.Values = this.ruleGroupInputValue;
                    } else if(this.listInputIsOperatorList == true && this.listInputIsPickList == true){
                      el.Values = this.ruleGroupInputValueMultiple.join();
                    }else if (this.listInputIsOperatorList == true) {
                      el.Values = this.ruleGroupInputValueMultiple.join();
                    } else if (this.listInputIsPickList == true) {
                      el.Values = this.ruleGroupInputValue;
                    } else {
                      el.Values = this.ruleGroupInputValue;
                    }
                  }
              
            });
            this.isTypeBaseInputValuePopup = false;
            this.addRuleParentType = null;
            this.inputRuleGroupIndex = -1;
            this.listInputCriteriaBaseType = null;
            this.listInputIsOperatorList = false;
            this.listInputIsPickList = false;
            this.listInputPickListValues = null;
            this.crmApiName = null;
            this.operatorType = null;
            this.ruleGroupInputValue = null;
            this.ruleGroupInputValueMultiple = [];
            this.ruleSeleted = null;
            this.ruleGroupDateDays = null;
          } else if (this.addRuleParentType == "customer") {
           
                this.filterLogicCustomer.Rule_Group.forEach((el, index) => {
                  if (index == this.inputRuleGroupIndex) {
                    if (this.listInputCriteriaBaseType == "datetime") {
                      el.Values =
                        this.ruleGroupDateDays + " " + this.ruleGroupInputValue;
                    } else if (this.listInputCriteriaBaseType == "boolean") {
                      el.Values = this.ruleGroupInputValue;
                    } else if(this.listInputIsOperatorList == true && this.listInputIsPickList == true){
                      el.Values = this.ruleGroupInputValueMultiple.join();
                    }else if (this.listInputIsOperatorList == true) {
                      el.Values = this.ruleGroupInputValueMultiple.join();
                    } else if (this.listInputIsPickList == true) {
                      el.Values = this.ruleGroupInputValue;
                    } else {
                      el.Values = this.ruleGroupInputValue;
                    }
              }
            });
            this.isTypeBaseInputValuePopup = false;
            this.addRuleParentType = null;
            this.inputRuleGroupIndex = -1;
            this.listInputCriteriaBaseType = null;
            this.listInputIsOperatorList = false;
            this.listInputIsPickList = false;
            this.listInputPickListValues = null;
            this.crmApiName = null;
            this.operatorType = null;
            this.ruleGroupInputValue = null;
            this.ruleGroupInputValueMultiple = [];
            this.ruleSeleted = null;
            this.ruleGroupDateDays = null;
          } else {
                  this.filterLogicCompetitor.Rule_Group.forEach((el, index) => {
                    if (index == this.inputRuleGroupIndex) {
                      if (this.listInputCriteriaBaseType == "datetime") {
                        el.Values =
                          this.ruleGroupDateDays +
                          " " +
                          this.ruleGroupInputValue;
                      } else if (this.listInputCriteriaBaseType == "boolean") {
                        el.Values = this.ruleGroupInputValue;
                      } else if(this.listInputIsOperatorList == true && this.listInputIsPickList == true){
                      el.Values = this.ruleGroupInputValueMultiple.join();
                    }else if (this.listInputIsOperatorList == true) {
                        el.Values = this.ruleGroupInputValueMultiple.join();
                      } else if (this.listInputIsPickList == true) {
                        el.Values = this.ruleGroupInputValue;
                      } else {
                        el.Values = this.ruleGroupInputValue;
                      }
                    }
              }
            );
            this.isTypeBaseInputValuePopup = false;
            this.addRuleParentType = null;
            this.inputRuleGroupIndex = -1;
            this.listInputCriteriaBaseType = null;
            this.listInputIsOperatorList = false;
            this.listInputIsPickList = false;
            this.listInputPickListValues = null;
            this.crmApiName = null;
            this.operatorType = null;
            this.ruleGroupInputValue = null;
            this.ruleGroupInputValueMultiple = [];
            this.ruleSeleted = null;
            this.ruleGroupDateDays = null;
          }
        }
      });
    },
    async fourthStepSaveSettings() {
      //Prospect
      let filterLogicProspectFinal;
      if (this.useProspect) {
          let ruleGroup = [];
          for (
            let j = 0;
            j < this.filterLogicProspect.Rule_Group.length;
            j++
          ) {
             ruleGroup.push({
              Crm_Api_Name:
                this.filterLogicProspect.Rule_Group[j]
                  .Crm_Api_Name.Crm_Api_Name,
              Crm_Display_Name: this.filterLogicProspect.Rule_Group[j]
                  .Crm_Api_Name.Crm_Display_Name,
              Operator_Id:
                this.filterLogicProspect.Rule_Group[j].Operator_Id.Key,
              Values:this.filterLogicProspect.Rule_Group[j].Values.split(','),
            });
          }
        filterLogicProspectFinal = {
          Group_Condition_Met: this.filterLogicProspect.Group_Condition_Met,
          Rule_Group: ruleGroup,
        };
      } else {
        filterLogicProspectFinal = null;
      }
      //Customer
      let filterLogicCustomerFinal;
      if (this.useCustomer) {
          let ruleGroup = [];
          for (
            let j = 0;
            j < this.filterLogicCustomer.Rule_Group.length;
            j++
          ) {
           ruleGroup.push({
              Crm_Api_Name:
                this.filterLogicCustomer.Rule_Group[j]
                  .Crm_Api_Name.Crm_Api_Name,
              Crm_Display_Name: this.filterLogicCustomer.Rule_Group[j]
                  .Crm_Api_Name.Crm_Display_Name,
              Operator_Id:
                this.filterLogicCustomer.Rule_Group[j].Operator_Id.Key,
              Values:this.filterLogicCustomer.Rule_Group[j].Values.split(','),
            });
          }
        filterLogicCustomerFinal = {
          Group_Condition_Met: this.filterLogicCustomer.Group_Condition_Met,
          Rule_Group: ruleGroup,
        };
      } else {
        filterLogicCustomerFinal = null;
      }
      //Competitor
      let filterLogicCompetitorFinal;
      if (this.useCompetitor) {
          let ruleGroup = [];
          for (
            let j = 0;
            j <
            this.filterLogicCompetitor.Rule_Group.length;
            j++
          ) {
            ruleGroup.push({
              Crm_Api_Name:
                this.filterLogicCompetitor.Rule_Group[j]
                  .Crm_Api_Name.Crm_Api_Name,
              Crm_Display_Name: this.filterLogicCompetitor.Rule_Group[j]
                  .Crm_Api_Name.Crm_Display_Name,
              Operator_Id:
                this.filterLogicCompetitor.Rule_Group[j].Operator_Id.Key,
              Values:this.filterLogicCompetitor.Rule_Group[j].Values.split(','),
            });
          }
        filterLogicCompetitorFinal = {
          Group_Condition_Met: this.filterLogicCompetitor.Group_Condition_Met,
          Rule_Group: ruleGroup,
        };
      } else {
        filterLogicCompetitorFinal = null;
      }
   
      let fourthStepSaveSettingsParams = {
        Connection_Id: this.connectionId,
        Use_Prospect: this.useProspect,
        Prospect_Module_Id:this.prospectModule?this.prospectModule:0,
        Filter_Logic_Prospect:
          filterLogicProspectFinal &&
          filterLogicProspectFinal.Rule_Group.length > 0
            ? filterLogicProspectFinal
            : null,
        Use_Customer: this.useCustomer,
        Customer_Module_Id:this.customerModule?this.customerModule:0,
        Filter_Logic_Customer:
          filterLogicCustomerFinal &&
          filterLogicCustomerFinal.Rule_Group.length > 0
            ? filterLogicCustomerFinal
            : null,
        Use_Competitor: this.useCompetitor,
        Competitor_Module_Id:this.competitorsModule?this.competitorsModule:0,
        Filter_Logic_Competitor:
          filterLogicCompetitorFinal &&
          filterLogicCompetitorFinal.Rule_Group.length > 0
            ? filterLogicCompetitorFinal
            : null,
      };
      this.$vs.loading();
      await this.axios
        .post(
          "/ws/IntegrationWizardCRMDataFilter/SaveSettings",
          fourthStepSaveSettingsParams
        )
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.stepProgressOnSave();
           if(!this.completedSteps.includes(5)){
            this.fifthStepGetSettings();
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Step5: Map Account Data Fields
    async fifthStepGetAvailableFields() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/IntegrationWizardFieldMapAccount/GetAvailableFields?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.accountModuleName = data.CRM_Module_Text;
          this.accountVVFieldOptions = data.VV_Field_List;
          this.accountCRMSelected = [];
          let crmSelect = [];
          let temp = data.CRM_Available_Fields;
          temp.forEach((element) => {
            let dd = this.accountFieldMapped;
            dd.forEach((crv) => {
              crmSelect.push(crv.VV_Field_Id);
              if (crv.Is_Update_Field) {
                this.keyFieldAccount = crv.Field_API_Name;
              }
              if (element.Field_API_Name == crv.Field_API_Name) {
                element.CurrentRowValues = crv;
                element.CurrentRowValues.VV_Field_Id = data.VV_Field_List.find(
                  ({ VV_Field_Id }) => VV_Field_Id === crv.VV_Field_Id
                );
              }
              //this.accountCrmSelected.push(crv.VV_Field_Id);
            });
            if (!element.CurrentRowValues) {
              element.CurrentRowValues = {
                Default_Value: "",
                Example: null,
                Field_API_Name: element.Field_API_Name,
                Overwrite: false,
                VV_Field_Id: null,
                Validation_Rule_Id: null,
              };
            }
          });
          this.accountCRMSelected = crmSelect;
          this.accountCRMAvailableFieldOptions = temp;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    fifthStepGetSettings() {
      this.$vs.loading();
      this.axios
        .get(
          "/ws/IntegrationWizardFieldMapAccount/GetSettings?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.accountFieldMapped = data.CurrentRowValues;
          this.accountMapUpdateBlankFields = data.Do_Not_Update_Blank;
          this.fifthStepGetAvailableFields();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    updateAccountFieldMapValues(index) {
      let crmSelected = [];
      this.accountCRMAvailableFieldOptions.forEach((element) => {
        if (element.CurrentRowValues.VV_Field_Id) {
          crmSelected.push(element.CurrentRowValues.VV_Field_Id.VV_Field_Id);
        }
      });
      this.accountCRMSelected = crmSelected;

      if (this.accountFieldMapped.length) {
        this.accountCRMAvailableFieldOptions[
          index
        ].CurrentRowValues.Overwrite = true;
        if (
          this.accountCRMAvailableFieldOptions[index].Available_Validation_Rules
            .length == 1
        ) {
          this.accountCRMAvailableFieldOptions[
            index
          ].CurrentRowValues.Validation_Rule_Id =
            this.accountCRMAvailableFieldOptions[
              index
            ].Available_Validation_Rules[0].Key;
        }
      } else {
        this.accountCRMAvailableFieldOptions[
          index
        ].CurrentRowValues.Default_Value = "";
        this.accountCRMAvailableFieldOptions[
          index
        ].CurrentRowValues.Validation_Rule_Id = "";
      }
    },
    async fifthStepSaveSettings() {
      let CurrentRowValues = [];
      for (let i = 0; i < this.accountCRMAvailableFieldOptions.length; i++) {
        if (
          this.accountCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id
        ) {
          CurrentRowValues.push({
            VV_Field_Id:
              this.accountCRMAvailableFieldOptions[i].CurrentRowValues
                .VV_Field_Id.VV_Field_Id,
            Field_API_Name:
              this.accountCRMAvailableFieldOptions[i].Field_API_Name,
            Field_Display_Name:
              this.accountCRMAvailableFieldOptions[i].Field_Display_Name,
            Is_Key_Field:
              this.accountCRMAvailableFieldOptions[i].Required_Field,
            Is_Update_Field:
              this.accountCRMAvailableFieldOptions[i].Field_API_Name ==
              this.keyFieldAccount
                ? true
                : false,
            Validation_Rule_Id:
              this.accountCRMAvailableFieldOptions[i].CurrentRowValues
                .Validation_Rule_Id,
            Default_Value:
              this.accountCRMAvailableFieldOptions[i].CurrentRowValues
                .Default_Value,
            Overwrite:
              this.accountCRMAvailableFieldOptions[i].CurrentRowValues
                .Overwrite,
          });
        }
      }
      let fifthStepSaveSettingsParams = {
        CurrentRowValues: CurrentRowValues,
        Connection_Id: this.connectionId,
        Do_Not_Update_Blank: this.accountMapUpdateBlankFields,
      };
      this.$vs.loading();
      await this.axios
        .post(
          "/ws/IntegrationWizardFieldMapAccount/SaveSettings",
          fifthStepSaveSettingsParams
        )
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.stepProgressOnSave();
          window.scrollTo(0, 0);
          if(!this.completedSteps.includes(6)){
              this.sixthStepGetSettings();
          }
          //this.sixthStepGetAvailableFields();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Step6: Map Contact Data Fields
    async sixthStepGetAvailableFields() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/IntegrationWizardFieldMapContact/GetAvailableFields?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.contactModuleName = data.CRM_Module_Text;
          let contactSelected = [];
          this.contactCRMSelected = [];
          let temp = data.CRM_Available_Fields;
          temp.forEach((element) => {
            this.contactFieldMapped.forEach((crv) => {
              if (crv.Is_Update_Field) {
                this.keyFieldContact = crv.Field_API_Name;
              }
              if (element.Field_API_Name == crv.Field_API_Name) {
                contactSelected.push(crv.VV_Field_Id);
                element.CurrentRowValues = crv;
                element.CurrentRowValues.VV_Field_Id = data.VV_Field_List.find(
                  ({ VV_Field_Id }) => VV_Field_Id === crv.VV_Field_Id
                );
                // this.contactCRMSelected.push(crv.VV_Field_Id.VV_Field_Id);
              }
            });
            if (!element.CurrentRowValues) {
              element.CurrentRowValues = {
                Default_Value: "",
                Example: null,
                Field_API_Name: element.Field_API_Name,
                Overwrite: false,
                VV_Field_Id: null,
                Validation_Rule_Id: null,
              };
            }
          });
          this.contactCRMSelected = contactSelected;
          this.contactVVFieldOptions = data.VV_Field_List;
          this.contactCRMAvailableFieldOptions = temp;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    sixthStepGetSettings() {
      this.$vs.loading();
      this.axios
        .get(
          "/ws/IntegrationWizardFieldMapContact/GetSettings?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.contactFieldMapped = data.CurrentRowValues;
          this.contactMapUpdateBlankFields = data.Do_Not_Update_Blank;
          this.sixthStepGetAvailableFields();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    updateContactFieldMapValues(index) {
      let crmSelected = [];
      this.contactCRMAvailableFieldOptions.forEach((element) => {
        if (element.CurrentRowValues.VV_Field_Id) {
          crmSelected.push(element.CurrentRowValues.VV_Field_Id.VV_Field_Id);
        }
      });
      this.contactCRMSelected = crmSelected;
      if (this.accountFieldMapped.length) {
        this.contactCRMAvailableFieldOptions[
          index
        ].CurrentRowValues.Overwrite = true;
        if (
          this.contactCRMAvailableFieldOptions[index].Available_Validation_Rules
            .length == 1
        ) {
          this.contactCRMAvailableFieldOptions[
            index
          ].CurrentRowValues.Validation_Rule_Id =
            this.contactCRMAvailableFieldOptions[
              index
            ].Available_Validation_Rules[0].Key;
        }
      } else {
        this.contactCRMAvailableFieldOptions[
          index
        ].CurrentRowValues.Validation_Rule_Id = "";
        this.contactCRMAvailableFieldOptions[
          index
        ].CurrentRowValues.Default_Value = "";
      }
    },
    async sixthStepSaveSettings() {
      let CurrentRowValues = [];
      for (let i = 0; i < this.contactCRMAvailableFieldOptions.length; i++) {
        if (
          this.contactCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id
        ) {
          CurrentRowValues.push({
            VV_Field_Id:
              this.contactCRMAvailableFieldOptions[i].CurrentRowValues
                .VV_Field_Id.VV_Field_Id,
            Field_API_Name:
              this.contactCRMAvailableFieldOptions[i].Field_API_Name,
            Field_Display_Name:
              this.contactCRMAvailableFieldOptions[i].Field_Display_Name,
            Is_Key_Field:
              this.contactCRMAvailableFieldOptions[i].Required_Field,
            Is_Update_Field:
              this.contactCRMAvailableFieldOptions[i].Field_API_Name ==
              this.keyFieldContact
                ? true
                : false,
            Validation_Rule_Id:
              this.contactCRMAvailableFieldOptions[i].CurrentRowValues
                .Validation_Rule_Id,
            Default_Value:
              this.contactCRMAvailableFieldOptions[i].CurrentRowValues
                .Default_Value,
            Overwrite:
              this.contactCRMAvailableFieldOptions[i].CurrentRowValues
                .Overwrite,
          });
        }
      }
      let sixthStepSaveSettingsParams = {
        CurrentRowValues: CurrentRowValues,
        Connection_Id: this.connectionId,
        Do_Not_Update_Blank: this.contactMapUpdateBlankFields,
      };
      this.$vs.loading();
      await this.axios
        .post(
          "/ws/IntegrationWizardFieldMapContact/SaveSettings",
          sixthStepSaveSettingsParams
        )
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.stepProgressOnSave();
          window.scrollTo(0, 0);
          if(!this.completedSteps.includes(7)){
              this.seventhStepGetSettings();
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Step7: Map Lead Data Fields
    async seventhStepGetAvailableFields() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/IntegrationWizardFieldMapLead/GetAvailableFields?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.leadModuleName = data.CRM_Module_Text;
          this.leadCRMSelected = [];
          let leadSelected = [];
          let temp = data.CRM_Available_Fields;
          temp.forEach((element) => {
            this.leadFieldMapped.forEach((crv) => {
              if (crv.Is_Update_Field) {
                this.keyFieldLead = crv.Field_API_Name;
              }
              if (element.Field_API_Name == crv.Field_API_Name) {
                leadSelected.push(crv.VV_Field_Id);
                element.CurrentRowValues = crv;
                element.CurrentRowValues.VV_Field_Id = data.VV_Field_List.find(
                  ({ VV_Field_Id }) => VV_Field_Id === crv.VV_Field_Id
                );
                // this.leadCrmSelected.push(crv.VV_Field_Id.VV_Field_Id);
              }
            });
            if (!element.CurrentRowValues) {
              element.CurrentRowValues = {
                Default_Value: "",
                Example: null,
                Field_API_Name: element.Field_API_Name,
                Overwrite: false,
                VV_Field_Id: null,
                Validation_Rule_Id: null,
              };
            }
          });
          this.leadCRMSelected = leadSelected;
          this.leadVVFieldOptions = data.VV_Field_List;
          this.leadCRMAvailableFieldOptions = data.CRM_Available_Fields;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    copyContactFieldMapping() {
      this.leadCRMAvailableFieldOptions = this.contactCRMAvailableFieldOptions;
      this.leadFieldMapped = this.contactFieldMapped;
      this.leadVVFieldOptions = this.contactVVFieldOptions;
    },
    seventhStepGetSettings() {
      this.$vs.loading();
      this.axios
        .get(
          "/ws/IntegrationWizardFieldMapLead/GetSettings?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.leadFieldMapped = data.CurrentRowValues;
          this.leadMapUpdateBlankFields = data.Do_Not_Update_Blank;
          this.seventhStepGetAvailableFields();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    updateLeadFieldMapValues(index) {
      let crmSelected = [];
      this.leadCRMAvailableFieldOptions.forEach((element) => {
        if (element.CurrentRowValues.VV_Field_Id) {
          crmSelected.push(element.CurrentRowValues.VV_Field_Id.VV_Field_Id);
        }
      });
      this.leadCRMSelected = crmSelected;
      if (this.leadFieldMapped.length) {
        this.leadCRMAvailableFieldOptions[
          index
        ].CurrentRowValues.Overwrite = true;
        if (
          this.leadCRMAvailableFieldOptions[index].Available_Validation_Rules
            .length == 1
        ) {
          this.leadCRMAvailableFieldOptions[
            index
          ].CurrentRowValues.Validation_Rule_Id =
            this.leadCRMAvailableFieldOptions[
              index
            ].Available_Validation_Rules[0].Key;
        }
      } else {
        this.leadCRMAvailableFieldOptions[
          index
        ].CurrentRowValues.Validation_Rule_Id = "";
        this.leadCRMAvailableFieldOptions[
          index
        ].CurrentRowValues.Default_Value = "";
      }
    },
    async seventhStepSaveSettings() {
      let CurrentRowValues = [];
      for (let i = 0; i < this.leadCRMAvailableFieldOptions.length; i++) {
        if (this.leadCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id) {
          CurrentRowValues.push({
            VV_Field_Id:
              this.leadCRMAvailableFieldOptions[i].CurrentRowValues.VV_Field_Id
                .VV_Field_Id,
            Field_API_Name: this.leadCRMAvailableFieldOptions[i].Field_API_Name,
            Field_Display_Name:
              this.leadCRMAvailableFieldOptions[i].Field_Display_Name,
            Is_Key_Field: this.leadCRMAvailableFieldOptions[i].Required_Field,
            Is_Update_Field:
              this.leadCRMAvailableFieldOptions[i].Field_API_Name ==
              this.keyFieldLead
                ? true
                : false,
            Validation_Rule_Id:
              this.leadCRMAvailableFieldOptions[i].CurrentRowValues
                .Validation_Rule_Id,
            Default_Value:
              this.leadCRMAvailableFieldOptions[i].CurrentRowValues
                .Default_Value,
            Overwrite:
              this.leadCRMAvailableFieldOptions[i].CurrentRowValues.Overwrite,
          });
        }
      }
      let seventhStepSaveSettingsParams = {
        CurrentRowValues: CurrentRowValues,
        Connection_Id: this.connectionId,
        Do_Not_Update_Blank: this.leadMapUpdateBlankFields,
      };
      this.$vs.loading();
      await this.axios
        .post(
          "/ws/IntegrationWizardFieldMapLead/SaveSettings",
          seventhStepSaveSettingsParams
        )
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.stepProgressOnSave();
          window.scrollTo(0, 0);
          if(!this.completedSteps.includes(8)){
            this.eighthStepGetLists();
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    //Step8: Determine Lead Handling
    async eighthStepGetLists() {
      this.$vs.loading();
      await this.axios
        .get(
          "/ws/IntegrationWizardLeadHandling/GetLists?connection_Id=" +
            this.connectionId
        )
        .then((response) => {
          let data = response.data;
          this.accountText = data.Available_Global_Lists.Account_Text;
          this.contactText = data.Available_Global_Lists.Contact_Text;
          this.leadText = data.Available_Global_Lists.Lead_Text;
          this.accountPluralText =
            data.Available_Global_Lists.Account_Plural_Text;
          this.contactPluralText =
            data.Available_Global_Lists.Contact_Plural_Text;
          this.leadPluralText = data.Available_Global_Lists.Lead_Plural_Text;

          this.userList = data.Available_Global_Lists.User_List;
          this.importingIntoSystemListOptions =
            data.Available_Global_Lists.Importing_Into_System_List;
          this.exportingIntoSystemListOptions =
            data.Available_Global_Lists.Exporting_Into_System_List;
          this.handlingDuplicateListOptions =
            data.Available_Global_Lists.Handling_Duplicate_List;
          this.doNotImportListOptions =
            data.Available_Global_Lists.Do_Not_Import_List;

          
          this.classifications = data.Available_Module_Lists.Classifications;
          this.recordListOptions = data.Available_Module_Lists.Record_Options;
          this.crmNameToDisplay = data.Available_Module_Lists.CRM_Name;
          this.eighthStepGetSettings();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    eighthStepGetSettings(){
      this.$vs.loading();
      this.axios.get("/ws/IntegrationWizardLeadHandling/GetSettings?connection_Id="+this.connectionId).then((response) => {
        let data = response.data;
          this.defaultAccountOwnerUserId = data.Global_Settings.Default_Account_Owner_User_Id;
          this.defaultContactOwnerUserId = data.Global_Settings.Default_Contact_Owner_User_Id;
          this.defaultLeadOwnerUserId = data.Global_Settings.Default_Lead_Owner_User_Id;
          this.importToCRMAccountOption = data.Global_Settings.ImportToCRM_Account_Option?data.Global_Settings.ImportToCRM_Account_Option:1;
          this.importToCRMContactOption = data.Global_Settings.ImportToCRM_Contact_Option;
          this.importToCRMLeadOption = data.Global_Settings.ImportToCRM_Lead_Option;
          this.importToCRMDuplicateHandlingOption = data.Global_Settings.ImportToCRM_Duplicate_Handling_Option;
          this.doNotImport = data.Global_Settings.Do_Not_Import;
          this.exportToCRMAccountOption = data.Global_Settings.ExportToCRM_Account_Option;
          this.exportToCRMContactOption = data.Global_Settings.ExportToCRM_Contact_Option;
          this.exportToCRMLeadOption = data.Global_Settings.ExportToCRM_Lead_Option;
          this.importNotificationEmail = data.Import_Notification_Emails;
          this.exportNotificationEmail = data.Export_Notification_Emails;

          this.allowQuickExportLooking = data.Module_Settings.Allow_QuickExport_Looking;
          this.formCaptureOptionId = data.Module_Settings.FormCapture_Option_Id;
          this.formCaptureClassificationId = data.Module_Settings.FormCapture_Classification_Id;
          this.formCaptureInsertNote = data.Module_Settings.FormCapture_Insert_Note;
          this.webVisitorOptionEnabled = data.Module_Settings.WebVisitor_Option_Enabled;
          this.webVisitorOptionVisitThreshold = data.Module_Settings.WebVisitor_Option_Visit_Threshold;
          this.webVisitorOptionVisitPeriod = data.Module_Settings.WebVisitor_Option_Visit_Period;
          this.webVisitorOptionId = data.Module_Settings.WebVisitor_Option_Id;
          this.webVisitorClassificationId = data.Module_Settings.WebVisitor_Classification_Id;
          this.webVisitorInsertNote = data.Module_Settings.WebVisitor_Insert_Note;
          this.webVisitorNoteOptionVisitThreshold = data.Module_Settings.WebVisitor_Note_Option_Visit_Threshold;
          this.webVisitorNoteOptionVisitPeriod = data.Module_Settings.WebVisitor_Note_Option_Visit_Period;
          this.allowQuickExportShopping = data.Module_Settings.Allow_QuickExport_Shopping;
          this.shoppingOptionEnabled = data.Module_Settings.Shopping_Option_Enabled;
          this.shoppingOptionScoreThreshold = data.Module_Settings.Shopping_Option_Score_Threshold;
          this.shoppingOptionScorePeriod = data.Module_Settings.Shopping_Option_Score_Period;
          this.shoppingOptionId = data.Module_Settings.Shopping_Option_Id;
          this.shoppingClassificationId = data.Module_Settings.Shopping_Classification_Id;
          this.shoppingInsertNote = data.Module_Settings.Shopping_Insert_Note;
          this.shoppingNoteOptionScoreThreshold = data.Module_Settings.Shopping_Note_Option_Score_Threshold;
          this.shoppingNoteOptionScorePeriod = data.Module_Settings.Shopping_Note_Option_Score_Period;
          this.allowQuickExportContact = data.Module_Settings.Allow_QuickExport_Contact;
          this.callTrackingInsertNote = data.Module_Settings.CallTracking_Insert_Note;
          this.lookingQuickExportOptionId = data.Module_Settings.Looking_Quick_Export_Option_Id;
          this.lookingQuickExportClassificationId = data.Module_Settings.Looking_Quick_Export_Classification_Id;
          this.shoppingQuickExportOptionId = data.Module_Settings.Shopping_Quick_Export_Option_Id;
          this.shoppingQuickExportClassificationId = data.Module_Settings.Shopping_Quick_Export_Classification_Id;
          this.contactQuickExportOptionId = data.Module_Settings.Contact_Quick_Export_Option_Id;
          this.contactQuickExportClassificationId = data.Module_Settings.Contact_Quick_Export_Classification_Id;
          this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    async eighthStepSaveSettings() {
      let eighthStepSaveSettingsParams = {
        Connection_Id: this.connectionId,
        Global_Settings: {
          Default_Account_Owner_User_Id: this.defaultAccountOwnerUserId,
          Default_Contact_Owner_User_Id: this.defaultContactOwnerUserId,
          Default_Lead_Owner_User_Id: this.defaultLeadOwnerUserId,
          ImportToCRM_Account_Option: this.importToCRMAccountOption,
          ImportToCRM_Contact_Option: this.importToCRMContactOption,
          ImportToCRM_Lead_Option: this.importToCRMLeadOption,
          ImportToCRM_Duplicate_Handling_Option:1,
          Do_Not_Import: this.doNotImport,
          ExportToCRM_Account_Option: this.exportToCRMAccountOption,
          ExportToCRM_Contact_Option: this.exportToCRMContactOption,
          ExportToCRM_Lead_Option: this.exportToCRMLeadOption,
          Import_Notification_Emails:this.importNotificationEmail,
          Export_Notification_Emails:this.exportNotificationEmail
        },
        Module_Settings: {
          Allow_QuickExport_Looking: this.allowQuickExportLooking,
          FormCapture_Option_Id: this.formCaptureOptionId,
          FormCapture_Classification_Id: this.formCaptureClassificationId,
          FormCapture_Insert_Note: this.formCaptureInsertNote,
          WebVisitor_Option_Enabled: this.webVisitorOptionEnabled,
          WebVisitor_Option_Visit_Threshold:
            this.webVisitorOptionVisitThreshold,
          WebVisitor_Option_Visit_Period: this.webVisitorOptionVisitPeriod,
          WebVisitor_Option_Id: this.webVisitorOptionId,
          WebVisitor_Classification_Id: this.webVisitorClassificationId,
          WebVisitor_Insert_Note: this.webVisitorInsertNote,
          WebVisitor_Note_Option_Visit_Threshold:
            this.webVisitorNoteOptionVisitThreshold,
          WebVisitor_Note_Option_Visit_Period:
            this.webVisitorNoteOptionVisitPeriod,
          Allow_QuickExport_Shopping: this.allowQuickExportShopping,
          Shopping_Option_Enabled: this.shoppingOptionEnabled,
          Shopping_Option_Score_Threshold: this.shoppingOptionScoreThreshold,
          Shopping_Option_Score_Period: this.shoppingOptionScorePeriod,
          Shopping_Option_Id: this.shoppingOptionId,
          Shopping_Classification_Id: this.shoppingClassificationId,
          Shopping_Insert_Note: this.shoppingInsertNote,
          Shopping_Note_Option_Score_Threshold:
            this.shoppingNoteOptionScoreThreshold,
          Shopping_Note_Option_Score_Period: this.shoppingNoteOptionScorePeriod,
          Allow_QuickExport_Contact: this.allowQuickExportContact,
          CallTracking_Insert_Note: this.callTrackingInsertNote,
          Looking_Quick_Export_Option_Id: this.lookingQuickExportOptionId,
          Looking_Quick_Export_Classification_Id: this.lookingQuickExportClassificationId,
          Shopping_Quick_Export_Option_Id: this.shoppingQuickExportOptionId,
          Shopping_Quick_Export_Classification_Id: this.shoppingQuickExportClassificationId,
          Contact_Quick_Export_Option_Id: this.contactQuickExportOptionId,
          Contact_Quick_Export_Classification_Id: this.contactQuickExportClassificationId,
        },
      };
      this.$vs.loading();
      await this.axios
        .post(
          "/ws/IntegrationWizardLeadHandling/SaveSettings",
          eighthStepSaveSettingsParams
        )
        .then((response) => {
          this.$vs.notify({
            title: "Success",
            text: response.data.Message,
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          this.stepProgressOnSave();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getDataOfCompletedSteps() {
      var elItems = document
        .getElementsByClassName("setup-wizard")[0]
        .getElementsByTagName("li");
      let i = 1;
      for (let elItem of elItems) {
        if (i == this.activeStep) {
          elItem.classList.add("active");
          elItem.classList.add("blue-arrow");
        } else {
          if (this.completedSteps.includes(i)) {
            elItem.classList.add("checked");
            elItem.classList.add("blue-line");
            elItem.classList.remove("active");
            elItem.classList.remove("blue-arrow");
          }
        }
        i++;
      }
      if (this.activeStep == 3) {
        this.thirdStepGetUserList();
      } else if (this.activeStep == 4) {
        this.thirdStepGetUserList();
        this.fourthStepGetAvailableFields();
      } else if (this.activeStep == 5) {
        this.thirdStepGetUserList();
        this.fourthStepGetAvailableFields();
        setTimeout(() => {
        this.fourthStepGetSettings();
        }, 5000);
        this.fifthStepGetSettings();
      } else if (this.activeStep == 6) {
        this.thirdStepGetUserList();
        this.fourthStepGetAvailableFields();
        setTimeout(() => {
        this.fourthStepGetSettings();
        }, 5000);
        this.fifthStepGetSettings();
        this.sixthStepGetSettings();
      } else if (this.activeStep == 7) {
        this.thirdStepGetUserList();
        this.fourthStepGetAvailableFields();
        setTimeout(() => {
        this.fourthStepGetSettings();
        }, 5000);
        this.fifthStepGetSettings();
        this.sixthStepGetSettings();
        this.seventhStepGetSettings();
      } else if (this.activeStep == 8) {
        this.thirdStepGetUserList();
        this.fourthStepGetAvailableFields();
        setTimeout(() => {
        this.fourthStepGetSettings();
        }, 5000);
        this.fifthStepGetSettings();
        this.sixthStepGetSettings();
        this.seventhStepGetSettings();
        this.eighthStepGetLists();
      }
    },
    backToIntegrationPartner() {
      setTimeout(() => {
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.isSettingWizard = false;
        if (token[uid].hasOwnProperty("completeStep")) {
          delete token[uid].completeStep;
          delete token[uid].wizardConnectionId;
          localStorage.setItem("VVtoken", JSON.stringify(token));
        }
        this.$router.push({
          path: "/configuration/integrationpartners/uid/" + uid,
        });
      }, 100);
    },
    selectEachUser() {
      if (
        this.selectedUsersToBeImported.length == this.usersToBeImported.length
      ) {
        this.selectedUsersToBeImportedAll = true;
      } else {
        this.selectedUsersToBeImportedAll = false;
      }
    },
    selectAllUsers() {
      if (this.selectedUsersToBeImportedAll) {
        this.usersToBeImported.forEach((element) => {
          if (!element.User_Exists) {
            this.selectedUsersToBeImported.push(element.User_Id);
          }
        });
      } else {
        this.usersToBeImported.forEach((element) => {
          if (!element.User_Exists) {
            let i = this.selectedUsersToBeImported.indexOf(element.User_Id);
            this.selectedUsersToBeImported.splice(i, 1);
          }
        });
      }
    },
    //step -4 change module name 
    changeModule(moduletype){
      this.moduleTypechange = moduletype;
      if(moduletype == "prospect"){
          if(this.filterLogicProspect.Rule_Group.length && this.filterLogicProspect.Rule_Group[0].Crm_Api_Name){
            this.warningModuleChange = true;
          } 
      } else if(moduletype == "customer"){
          if(this.filterLogicCustomer.Rule_Group.length && this.filterLogicCustomer.Rule_Group[0].Crm_Api_Name){
            this.warningModuleChange = true;
          } 
      } else {
          if(this.filterLogicCompetitor.Rule_Group.length && this.filterLogicCustomer.Rule_Group[0].Crm_Api_Name){
            this.warningModuleChange = true;
          }
      }
    },
    yesChangeModule(){
      if(this.moduleTypechange =='prospect'){
        this.oldProspectModule = this.prospectModule;
          this.filterLogicProspect.Rule_Group =  [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
          }]
      } else if(this.moduleTypechange =='customer'){
        this.oldCustomerModule = this.customerModule;
           this.filterLogicCustomer.Rule_Group =  [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
          }]
      } else {
        this.oldCompetitorsModule = this.competitorsModule;
           this.filterLogicCompetitor.Rule_Group =  [{
              Crm_Api_Name:null,
              Crm_Display_Name:null,
              Operator_Id:null,
              Values:null,
          }]
      }
      this.warningModuleChange = false;
    },
    cancelModuleChange(){
      if(this.moduleTypechange == 'prospect'){
        this.prospectModule = this.oldProspectModule;
      } else if(this.moduleTypechange == 'customer'){
        this.customerModule = this.oldCustomerModule;
      } else {
        this.competitorsModule = this.oldCompetitorsModule;
      }
      this.moduleTypechange = "";
      this.warningModuleChange = false;
    },
    crmFieldChanged(index,type){
      if(type == 'prospect'){
        this.filterLogicProspect.Rule_Group[index].Operator_Id = null;
        this.filterLogicProspect.Rule_Group[index].Values = null;
      } else if(type == 'customer'){
        this.filterLogicCustomer.Rule_Group[index].Operator_Id = null;
        this.filterLogicCustomer.Rule_Group[index].Values = null;
      } else {
        this.filterLogicCompetitor.Rule_Group[index].Operator_Id = null;
        this.filterLogicCompetitor.Rule_Group[index].Values = null;
      }
    },
     addValueToRule(){
        this.ruleGroupInputValueMultiple.push(this.ruleGroupInputValue);
        this.ruleGroupInputValue = '';
      },
      removeRuleSelectedValue(){
        const index = this.ruleGroupInputValueMultiple.indexOf(this.ruleSeleted);
        this.ruleGroupInputValueMultiple.splice(index,1);
        this.ruleSeleted =null;
      },
  },
};
</script>